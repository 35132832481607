<template>
  <div class="table-filter">
    <b-form
      class="pos-r"
      @submit.prevent="handlerPagination"
    >
      <b-form-input
        v-model="search"
        type="text"
        class="filter-search"
        placeholder="Поиск"
        @input="handlerPagination"
      />
      <b-button
        v-if="search.length > 0"
        class="btn-clear-search-str"
        @click.prevent="clear"
      />
    </b-form>
  </div>
</template>

<script>
  export default {
    name: 'DevicesTableFilter',
    data() {
      return {
        search: ''
      }
    },

    methods: {
      handlerPagination() {
        this.$emit('handler_pagination', this.search)
      },

      clear() {
        this.search = ''
        this.$emit('clear')
        this.handlerPagination()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .table-filter {
    .btn-clear-search-str {
      position: absolute;
      top: 9px;
      right: 9px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      overflow: hidden;
      background: #efefef;
      padding: 0;

      &:before,
      &:after {
        content: '';
        height: 1px;
        width: 8px;
        background: #888888;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%) rotate(-45deg);
      }

      &:after {
        transform: translate(-50%) rotate(45deg);
      }
    }
  }
</style>
