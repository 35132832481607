var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('b-sidebar', {
    attrs: {
      "id": "utm-device-sidebar",
      "right": "",
      "shadow": "",
      "backdrop": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref) {
        var hide = _ref.hide;
        return [_c('div', {
          staticClass: "sidebar-title-text"
        }, [_vm._v("Настройка УТМ")]), _c('img', {
          staticClass: "close-sidebar cursor",
          attrs: {
            "src": "/img/integrations/icon-sidebar-close.svg",
            "alt": ""
          },
          on: {
            "click": hide
          }
        })];
      }
    }, {
      key: "footer",
      fn: function (_ref2) {
        var hide = _ref2.hide;
        return [_c('div', {
          staticClass: "b-action-buttons"
        }, [_c('b-button', {
          staticClass: "cursor",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.setUtm
          }
        }, [_vm._v(" " + _vm._s('Сохранить') + " ")]), _c('b-button', {
          staticClass: "cursor ml-3",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": hide
          }
        }, [_vm._v(" Отмена ")])], 1)];
      }
    }])
  }, [_c('div', {
    staticClass: "info-message"
  }, [_vm._v(" Универсальный транспортный модуль (УТМ) – специальное программное обеспечение, которое позволяет обмениваться информацией с Единой государственной автоматизированной информационной системой (ЕГАИС) ")]), _c('e-select', {
    staticClass: "mt-3",
    attrs: {
      "label": "Магазин",
      "disabled": "",
      "options": _vm.getBranchList,
      "value": [this.currentBranch],
      "selected_type": "text"
    }
  }), _c('e-select', {
    staticClass: "mt-3",
    attrs: {
      "label": "Организация",
      "options": _vm.entities,
      "search_in_drop": "",
      "selected_type": "text"
    },
    scopedSlots: _vm._u([{
      key: "custom_name",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "entity w-100"
        }, [_c('div', {
          staticClass: "name"
        }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.name) + " ")]), _c('div', {
          staticClass: "inn_kpp"
        }, [_c('p', {
          staticClass: "inn"
        }, [_vm._v("ИНН: " + _vm._s(item.inn ? item.inn : '-'))]), _c('p', {
          staticClass: "kpp"
        }, [_vm._v("КПП: " + _vm._s(item.kpp ? item.kpp : '-'))])])])];
      }
    }]),
    model: {
      value: _vm.current_utm.entity,
      callback: function ($$v) {
        _vm.$set(_vm.current_utm, "entity", $$v);
      },
      expression: "current_utm.entity"
    }
  }), _c('e-input', {
    staticClass: "mt-3",
    attrs: {
      "label": "ip",
      "placeholder": "http://192.168.1.10:8080"
    },
    model: {
      value: _vm.current_utm.host,
      callback: function ($$v) {
        _vm.$set(_vm.current_utm, "host", $$v);
      },
      expression: "current_utm.host"
    }
  }), _c('e-select', {
    staticClass: "mt-3",
    attrs: {
      "options": _vm.storage_list,
      "selected_type": "text",
      "label": "Место хранения"
    },
    model: {
      value: _vm.current_utm.storage,
      callback: function ($$v) {
        _vm.$set(_vm.current_utm, "storage", $$v);
      },
      expression: "current_utm.storage"
    }
  })], 1), _c('div', {
    staticClass: "d-flex"
  }, [_c('b-button', {
    staticClass: "ml-auto",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.createUtm
    }
  }, [_c('img', {
    staticClass: "mr",
    attrs: {
      "src": "/img/icons/btn-plus.svg",
      "alt": ""
    }
  }), _vm._v(" Добавить ")])], 1), _c('b-card', {
    staticClass: "p-0 mt-3",
    attrs: {
      "no-body": ""
    }
  }, [_c('resizable-table', {
    attrs: {
      "table_name": "utm-device",
      "inside_card": false,
      "busy": _vm.$apollo.queries.getUtmList.loading,
      "empty_text": "Пусто",
      "default_fields": _vm.fields,
      "items": _vm.items
    },
    on: {
      "row-click": _vm.rowClicked
    },
    scopedSlots: _vm._u([{
      key: "head_id",
      fn: function () {
        return [_c('e-checkbox')];
      },
      proxy: true
    }, {
      key: "body_branch",
      fn: function (data) {
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "justify-content": "start"
          }
        }, [_vm._v(" " + _vm._s(data.value.name) + " ")])];
      }
    }, {
      key: "body_entity",
      fn: function (data) {
        return [_c('entity-info', {
          attrs: {
            "entity": data.value
          }
        })];
      }
    }, {
      key: "body_host",
      fn: function (data) {
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "justify-content": "start"
          }
        }, [_vm._v(" " + _vm._s(data.value) + " ")])];
      }
    }, {
      key: "body_id",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "d-flex justify-content-center"
        }, [_c('e-checkbox', {
          attrs: {
            "checked": _vm.active(item === null || item === void 0 ? void 0 : item.id)
          },
          on: {
            "click": function ($event) {
              return _vm.select(item === null || item === void 0 ? void 0 : item.id);
            }
          }
        })], 1)];
      }
    }])
  }), _c('custom-navbar', {
    attrs: {
      "items": _vm.selected
    },
    scopedSlots: _vm._u([{
      key: "buttons",
      fn: function () {
        return [_c('b-button', {
          attrs: {
            "variant": "dark"
          },
          on: {
            "click": _vm.remove
          }
        }, [_vm._v("Удалить")])];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }