var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "14",
      "height": "16",
      "viewBox": "0 0 14 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clip0_15235_162765)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M6.25 5H10M6.25 8H10M6.25 11H10M5.5 1.25H2.125C1.504 1.25 1 1.754 1 2.375V13.625C1 14.246 1.504 14.75 2.125 14.75H11.875C12.496 14.75 13 14.246 13 13.625V2.375C13 1.754 12.496 1.25 11.875 1.25H5.5ZM4 5H4.008V5.008H4V5ZM4 8H4.008V8.008H4V8ZM4 11H4.008V11.008H4V11Z",
      "stroke": _vm.color,
      "stroke-width": "1.13",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })]), _c('defs', [_c('clipPath', {
    attrs: {
      "id": "clip0_15235_162765"
    }
  }, [_c('rect', {
    attrs: {
      "width": "14",
      "height": "16",
      "fill": "white"
    }
  })])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }