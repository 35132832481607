<template>
  <div id="scales-tab">
    <plu-product-list-modal
      :list_id="list_id"
      :list_name="list_name"
      @close="close"
    />
    <div class="d-flex">
      <!--      <i-select-->
      <!--        v-model="cash_filter"-->
      <!--        placeholder="Все кассы"-->
      <!--        style="width: 116px; margin-right: 15px"-->
      <!--      >-->
      <!--        <i-option value="all">Все весы</i-option>-->
      <!--        <i-option value="shtrih_m"> Штрих М 15-2.5</i-option>-->
      <!--      </i-select>-->
      <!--      <i-select-->
      <!--        v-model="status_filter"-->
      <!--        placeholder="Все статусы"-->
      <!--        style="width: 116px"-->
      <!--      >-->
      <!--        <i-option value="all"> Все статусы</i-option>-->
      <!--        <i-option value="active"> Активные</i-option>-->
      <!--        <i-option value="disabled"> Выключенные</i-option>-->
      <!--      </i-select>-->
      <b-overlay
        class="ml-auto d-inline-block"
        :show="isLoading"
        rounded="sm"
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
      >
        <e-button
          size="m"
          variant="primary"
          plus
          @click="addScale"
        >
          Добавить список
        </e-button>
      </b-overlay>
    </div>
    <b-card
      style="margin-top: 16px; display: flex; flex: 1"
      no-body
      class="p-0"
    >
      <devices-table-filter
        style="margin: 16px; width: 270px"
        @handler_pagination="search"
      />
      <resizable-table
        table_name="scales"
        class="b-table__wrapper"
        :default_fields="fields"
        :busy="isLoading"
        :items="items"
      >
        <!--        @row-clicked="openDevice"-->
        <template #head_id>
          <e-checkbox
            :checked="select_all"
            @click="changeSelectAll"
          />
        </template>
        <template #body_id="data">
          <div
            class="b-statuses"
            :class="{ success: data?.item?.counts?.active_items === 0 }"
          ></div>
          <b-form-checkbox
            :checked="selected?.some((el) => el === data?.item?.id)"
            @change="(val) => addSelected(val, data?.item?.id)"
          />
        </template>
        <template #body_branch>
          {{ currentBranch.name ?? '-' }}
        </template>
        <template #head_settings>
          <div class="settings-block__head">
            <img
              src="/img/icons/settings2.svg"
              alt="settings"
              style="opacity: 0.6"
            />
          </div>
        </template>
        <template #body_settings="{ item }">
          <div class="settings-block">
            <div class="button">
              <img
                src="/img/icons/settings2.svg"
                alt="settings"
                @click="clickSettings(item)"
              />
            </div>
          </div>
        </template>
        <template #body_last_online="data">
          {{ formatDate(data.value) }}
        </template>
        <template #body_status="data">
          <b-badge
            :variant="`${
              !data?.item?.devices
                ? 'outline-secondary'
                : data?.item?.counts?.active_items === 0
                ? 'outline-success'
                : 'outline-danger'
            }`"
          >
            <div class="bage-status">
              <span v-if="!data?.item?.devices">Нет весов</span>
              <span v-else>
                {{ data?.item?.counts?.active_items === 0 ? 'Связть есть' : 'Нет связи' }}
              </span>
              <span
                v-if="data?.item?.counts?.active_items !== 0 && data?.item?.devices"
                class="numbers"
              >
                {{ data?.item?.counts?.active_items }}/{{ data?.item?.counts?.count }}
              </span>
            </div>
          </b-badge>
        </template>
        <template #body_products="{ item }">
          <div class="product_list">
            <list-svg />
            <div
              class="text-line"
              @click="product_list(item)"
              @click.stop
            >
              {{ item.name }}
            </div>
          </div>
        </template>
        <!--        <template #cell(active)="data">-->
        <!--          <b-badge :variant="`${data.item.active ? 'success' : 'danger'}`">-->
        <!--            {{ data.item.active ? 'Активна' : 'Выключена' }}-->
        <!--          </b-badge>-->
        <!--        </template>-->
      </resizable-table>
      <table-navbar
        :items="selected"
        hide_print
        :scale_active="selectedElement.active"
        :clear_selected="clearSelected"
        items_name="scales"
        @remove_items="removeLists"
        @scalesLoad="scalesLoad"
      />
    </b-card>
  </div>
</template>

<script>
  import DevicesTableFilter from '@/views/settings/components/devices/DevicesTableFilter'
  import { mapGetters } from 'vuex'
  import TableNavbar from '@/views/shared/components/table/TableNavbar'
  import PluProductListModal from '@/views/settings/components/PluProductListModal.vue'
  import ListSvg from '../../../../../public/img/icons/ListSvg'

  export default {
    name: 'ScalesDevices',
    components: { ListSvg, PluProductListModal, DevicesTableFilter, TableNavbar },
    apollo: {
      PluLists: {
        query: require('../../gql/PluLists.graphql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            input: {
              branch: this.currentBranch.id,
              search: this.search_str,
              visible: false
            }
          }
        },
        result({ data }) {
          if (data?.PluLists) {
            this.items = data.PluLists.map((item) => {
              item.counts = {
                count: item?.devices?.length,
                active_items: item?.devices?.reduce((acc, obj) => {
                  if (!obj.status) {
                    return acc + 1
                  }
                  return acc
                }, 0)
              }
              return item
            })
          } else {
            this.items = []
          }
          this.isLoading = false
        },
        error() {
          this.isLoading = false
        }
      }
    },
    data() {
      return {
        list_name: '',
        list_id: '',
        isLoading: true,
        show_list: false,
        currentDeviceId: null,
        all_integrations: {},
        status_filter: 'all',
        cash_filter: 'all',
        search_str: '',
        fields: [
          { key: 'id', label: '', thStyle: 'width: 50px', checked: true },
          { key: 'settings', label: '222', thStyle: 'width: 50px', checked: true },
          { key: 'products', label: 'Список', thStyle: 'width: 150px', checked: true },
          { key: 'branch', label: 'Магазин', checked: true },
          { key: 'last_online', label: 'Дата загрузки', checked: true },
          { key: 'status', label: 'Состояние', checked: true }
        ],
        items: [],
        selected: [],
        select_all: false
      }
    },

    watch: {
      items(newItems) {
        let currentSelectedAfterPagination = newItems.filter((item) => {
          return this.selected.some((el) => item.id === el.id)
        })
        if (currentSelectedAfterPagination.length === this.items.length) {
          this.select_all = true
        } else {
          this.select_all = false
        }
      }
    },
    mounted() {
      if (this.$route.query.device_modal) {
        this.currentDeviceId = this.$route.query.device_modal
        this.$bvModal.show('plu_product_list_modal')
      }
    },

    methods: {
      changeSelectAll() {
        this.select_all = !this.select_all
        if (!this.select_all) {
          this.items.forEach((obj) => (this.selected = this.selected.filter((item) => obj.id !== item)))
          return
        }

        let arrayCurrenSelected = []
        this.items.forEach((item) => {
          const id = item.id
          if (!this.selected.some((obj) => obj === id)) {
            arrayCurrenSelected.push(item.id)
          }
        })
        this.selected = [...this.selected, ...arrayCurrenSelected]
      },
      addSelected(val, idItem) {
        if (val) this.selected = [...this.selected, idItem]
        else {
          this.selected = this.selected?.filter((el) => el !== idItem)
        }
        let currentSeleted = this.items.filter((item) => {
          return this.selected.some((el) => item.id === el)
        })
        if (currentSeleted.length < this.items.length) this.select_all = false
        if (currentSeleted.length === this.items.length) this.select_all = true
      },

      clearSelected() {
        this.select_all = false
        this.selected = []
      },

      clickSettings(item) {
        if (item?.id)
          this.$router.push({
            name: 'devices.scale',
            query: { id: item?.id, name: item?.name, visible: item?.visible }
          })
      },
      async addScale() {
        await this.$router.push({
          name: 'devices.scale.new'
        })
      },
      close() {
        this.list_id = ''
        this.list_name = ''
        this.currentDeviceId = null
        let query = { ...this.$route.query }
        delete query.device_modal
        this.$router.replace({ query: query }).catch((er) => er)
      },
      search(data) {
        this.search_str = data
      },
      openDevice(item) {
        this.$router.push({ name: 'devices.scale', query: { id: item.id, name: item?.name } })
      },
      remove_items() {
        this.selected = []
      },
      async removeLists(items) {
        try {
          await this.$apollo.query({
            query: require('../../gql/DeletePluLists.graphql'),
            variables: {
              input: {
                ids: items
              }
            }
          })
          this.selected = []
          await this.$apollo.queries.PluLists.refetch()
        } catch (e) {
          console.log(e)
        }
      },
      scalesLoad() {
        try {
          this.$apollo.mutate({
            mutation: require('../../gql/CreateTask.graphql'),
            variables: {
              input: {
                branch: this.currentBranch.id,
                type: 'SCALES',
                status: 'AWAITING',
                products: {
                  action: 'CREATE'
                }
              }
            }
          })
          this.$noty.show('Успешно')
        } catch (e) {
          console.error(e)
          this.$noty.error('Ошибка загрузки')
        }
        this.selected = []
      },
      scalesDrop() {
        try {
          this.$apollo.mutate({
            mutation: require('../../gql/CreateTask.graphql'),
            variables: {
              input: {
                branch: this.currentBranch.id,
                type: 'SCALES',
                status: 'AWAITING',
                products: {
                  action: 'DELETE'
                }
              }
            }
          })
          this.$noty.show('Успешно')
        } catch (e) {
          console.error(e)
          this.$noty.error('Ошибка сброса')
        }
        this.selected = []
      },
      formatDate(date) {
        if (!date) return '-'
        return new Intl.DateTimeFormat('ru-RU').format(new Date(date))
      },
      async disable() {
        await this.$apollo.mutate({
          mutation: require('../../gql/updateDevice.graphql'),
          variables: {
            input: {
              id: this.selected[0],
              branch: this.currentBranch.id,
              active: false
            }
          }
        })
        this.selected = []
        await this.$apollo.queries.PluLists.refetch()
      },
      async enable() {
        await this.$apollo.mutate({
          mutation: require('../../gql/updateDevice.graphql'),
          variables: {
            input: {
              id: this.selected[0],
              branch: this.currentBranch.id,
              active: true
            }
          }
        })
        this.selected = []
        await this.$apollo.queries.PluLists.refetch()
      },
      product_list(list) {
        this.list_id = list.id
        this.list_name = list.name
        this.$bvModal.show('plu_product_list_modal')
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      }),
      selectedElement() {
        return this.items.find((el) => el.id === this.selected[0]) || {}
      },
      table_items() {
        return this.items?.filter((el) => {
          let flag = false
          if (this.cash_filter === 'all') flag = true
          if (this.cash_filter !== 'all' && el?.type === this.cash_filter) flag = true

          if (this.status_filter === 'active') flag = flag && el.active === true
          if (this.status_filter === 'disabled') flag = flag && el.active === false

          flag = flag && (el.name?.includes(this.search_str) || ('' + el.number)?.includes(this.search_str))

          return flag
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .badge-danger {
      background: #ffebee !important;
      color: #e53835 !important;
    }

    .badge-outline-danger {
      border: 1px solid #e53835;
      border-radius: 3px;
      background: #ffffff !important;
      color: #e53835 !important;
    }

    .badge-outline-success {
      border: 1px solid #00cb91;
      border-radius: 3px;
      background: #ffffff !important;
      color: #00cb91 !important;
    }

    .badge-outline-secondary {
      border: 1px solid #888888;
      border-radius: 3px;
      background: #ffffff !important;
      color: #888888 !important;
    }

    .modal-footer button {
      margin: 0px;
    }

    .info-modal {
      background: #eef9ff;
      margin-left: 22px;
      padding: 22px;
      color: #313131;
      margin-right: 22px;
    }

    .alert-header {
      margin-left: 0px;
    }

    .alert-footer {
      margin-left: 0px;
    }

    .spinner-border {
      margin-top: 0px;
    }

    table tbody tr:hover {
      background: transparent;
    }

    table tr td {
      border-right: none;
      padding: 16px;
    }

    .table-inner {
      border-left: none !important;
      border-right: none !important;
    }

    table .row:hover {
      animation: none !important;
    }

    table .row-field {
      border-bottom: 1px solid #e1e1e1 !important;
    }

    th {
      padding: 10px;
    }
  }

  .b-statuses {
    width: 2px !important;
    height: 32px !important;
    position: absolute;
    left: 0;
    top: 50%;
    background: #e53835;
    border-radius: 0 1px 1px 0;
    transform: translate(0%, -50%);

    &.success {
      background: #00cb91;
    }
  }

  .text-line {
    text-decoration-line: underline;
    text-underline-offset: 3px;
    margin-left: 8px;
    cursor: pointer;
  }

  .product_list {
    display: flex;
  }

  .bage-status {
    .numbers {
      width: 23px;
      height: 18px;
      padding: 2px 4px;
      margin-left: 6px;
      background: #e53835;
      border-radius: 4px;
      color: #fff;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .settings-block {
    transition: all 0.1s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;

    .button {
      cursor: pointer;
      width: 24px !important;
      height: 24px !important;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background: #efefef;
        border-radius: 2px;
      }
    }
  }

  .settings-block__head {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }

  #scales-tab {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
</style>
