var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.is_show ? _c('div', {
    staticClass: "label-modal"
  }, [_c('div', {
    staticClass: "label-modal__block"
  }, [_c('div', {
    staticClass: "label-modal__content"
  }, [_vm._t("default")], 2)]), _c('div', {
    staticClass: "label-modal__footer"
  })]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }