<template>
  <div
    v-if="is_show"
    class="label-modal"
  >
    <div class="label-modal__block">
      <div class="label-modal__content">
        <slot></slot>
      </div>
    </div>
    <div class="label-modal__footer"></div>
  </div>
</template>

<script>
  export default {
    name: 'LabelInputModal',
    props: {
      is_show: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .label-modal {
    position: relative;
    display: inline-block;
    margin-bottom: 13px;
    width: 230px;

    &__block {
      width: 100%;
      position: absolute;
      bottom: 0;
      right: 25%;
    }

    &__content {
      width: fit-content;
      padding: 7px 12px;
      border-radius: 6px;
      background: var(--text-primary-gray-700, #313131);
      box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.15);
      margin-bottom: 20px;
    }

    &__footer {
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 20px solid var(--text-primary-gray-700, #313131);
      margin: 0;
      position: absolute;
      bottom: 0;
      left: -10%;
    }
  }
</style>
