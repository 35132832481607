<template>
  <b-modal
    id="cash-load-modal"
    centered
    hide-footer
    size="md"
  >
    <template #modal-header>
      <div class="header-container">
        <div class="title-block d-flex justify-content-between w-100">
          <div class="header-block-title">
            <h5 class="title">Загрузка кассы</h5>

            <div class="header-descriptoin">
              <div class="header-descriptoin__delimiter"></div>
              <div>Касса № {{ current_item.number ?? '' }}</div>
              <div class="header-descriptoin__delimiter"></div>
              <div
                :class="{
                  'active-device': current_item.active,
                  'no-active-device': !current_item.active
                }"
              >
                {{ current_item.active ? 'Активна' : 'Не активеа' }}
              </div>
            </div>
          </div>

          <b-button class="close">
            <img
              src="/img/icons/modal_close.svg"
              alt=""
              @click="hideModal"
            />
          </b-button>
        </div>

        <div
          v-if="showInfo"
          class="info-container"
        >
          <div class="d-flex align-items-center">
            <img
              src="/img/icons/info-blue.svg"
              alt="info"
            />
            <p>Система обновит все данные на кассе. Мы сообщим, когда процесс завершится</p>
          </div>
        </div>
      </div>
    </template>

    <div class="modal-body">
      <div class="modal-content">
        <form class="modal-content__form">
          <div class="modal-content__form-checkbox-box">
            <b-form-checkbox v-model="isDownloadStaff" />
            <label class="modal-content__form-checkbox-box-label">Загрузить персонал</label>
          </div>
          <div class="modal-content__form-checkbox-box">
            <b-form-checkbox
              v-model="isDiscountsAndRestrictions"
              :disabled="true"
            />
            <label class="modal-content__form-checkbox-box-label-in-developing">Скидки и ограничения</label>
            <div class="in-developing">в разработке</div>
          </div>

          <div class="modal-content__form-checkbox-box">
            <b-form-checkbox
              v-model="isDiscountCards"
              :disabled="true"
            />
            <label class="modal-content__form-checkbox-box-label-in-developing">Дисконтные карты</label>
            <div class="in-developing">в разработке</div>
          </div>
        </form>
      </div>

      <template>
        <div class="modal-body__footer">
          <div class="b-action-buttons">
            <b-button
              variant="primary"
              class="cursor"
              @click="loadCashRegisters"
            >
              Загрузить
            </b-button>
            <b-button
              variant="outline-primary"
              class="cursor ml-3"
              @click="hideModal"
            >
              Отмена
            </b-button>
          </div>
        </div>
      </template>
    </div>
  </b-modal>
</template>

<script>
  export default {
    name: 'CashLoadModal',

    props: {
      current_item: {
        type: Object,
        default: () => {}
      }
    },

    data() {
      return {
        showInfo: true,
        isDownloadStaff: false,
        isDiscountsAndRestrictions: false,
        isDiscountCards: false
      }
    },

    methods: {
      hideModal() {
        this.$bvModal.hide('cash-load-modal')
        this.clearForm()
      },

      clearForm() {
        this.isDiscountCards = false
        this.isDownloadStaff = false
        this.isDiscountsAndRestrictions = false
      },

      async loadCashRegisters() {
        try {
          await this.$apollo.mutate({
            mutation: require('../../integrations/gql/UploadClassif.gql'),
            variables: {
              integration: this.current_item?.integration.id,
              branch: this.current_item?.branch?.id,
              cash_id: this.current_item?.id
            }
          })

          await this.$apollo.mutate({
            mutation: require('../gql/updateCash.graphql'),
            variables: {
              input: {
                id: this.current_item?.id,
                is_disc_card: this.isDiscountCards,
                is_discounts: this.isDiscountsAndRestrictions,
                upload_users: this.isDownloadStaff,
                branch: this.current_item?.branch?.id
              }
            }
          })

          this.$emit('refetch')
          this.$noty.show(`Загрузка кассы сохранена!`)
          this.hideModal()
        } catch (err) {
          console.error('error: ', err)
          this.$noty.show(`Что-то пошло не так. Попробуйте еще раз`)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header-container {
    width: 100%;
    .header-block-title {
      display: flex;
      align-items: center;
    }
    .header-descriptoin {
      margin-top: 2px;
      display: flex;
      align-items: center;
      color: var(--text-primary-gray-700, #313131);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      &__delimiter {
        border-radius: 14px;
        background: var(--text-placeholder-gray-300, #bcbcbc);
        width: 1px;
        height: 12px;
        margin-left: 11px;
        margin-right: 11px;
      }

      .active-device {
        color: var(--text-successful-green-400, #00cb91);
      }
      .no-active-device {
        color: red;
      }
    }
    .title-block {
      margin-bottom: 16px;
      .title {
        margin: 0;
        font-family: 'Gilroy-Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #313131;
      }
    }
    .info-container {
      justify-content: space-between;
      align-items: center;
      padding: 12px;
      width: 100%;
      background: #eef9ff;
      border: 1px solid #99daff;
      border-radius: 2px;

      p {
        margin: 0 0 0 13px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #313131;
      }
    }
  }

  .modal-body {
    &__footer {
      margin-top: 10px;
      margin-bottom: 22px;
    }
  }
  .modal-content {
    padding: 0;

    &__form-checkbox-box {
      display: flex;
      align-items: center;
      margin-bottom: 18px;
    }

    &__form-checkbox-box-label {
      margin: 0;
      color: var(--text-primary-gray-700, #313131);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &__form-checkbox-box-label-in-developing {
      margin: 0;
      color: var(--secondary-b-300, #a4a9b8);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .in-developing {
    color: var(--secondary-b-300, #a4a9b8);
    margin-left: 5px;
    display: flex;
    padding: 2px 6px;
    justify-content: center;
    align-items: center;

    border-radius: 100px;
    background: var(--secondary-b-50, #f2f3f6);
  }
</style>
