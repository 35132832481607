var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('download-cash-docs', {
    on: {
      "download": _vm.cashDownload
    }
  }), _c('cash-load-modal', {
    attrs: {
      "current_item": _vm.currentItemToLoad
    }
  }), _c('div', {
    staticClass: "d-flex"
  }, [_c('i-select', {
    staticStyle: {
      "width": "116px",
      "margin-right": "15px"
    },
    attrs: {
      "placeholder": "Все кассы"
    },
    model: {
      value: _vm.cash_filter,
      callback: function ($$v) {
        _vm.cash_filter = $$v;
      },
      expression: "cash_filter"
    }
  }, [_c('i-option', {
    attrs: {
      "value": "all"
    }
  }, [_vm._v("Все кассы")]), _c('i-option', {
    attrs: {
      "value": "evotor"
    }
  }, [_vm._v("Эвотор")]), _c('i-option', {
    attrs: {
      "value": "dklink"
    }
  }, [_vm._v("DKLink")])], 1), _c('i-select', {
    staticStyle: {
      "width": "116px"
    },
    attrs: {
      "placeholder": "Все статусы"
    },
    model: {
      value: _vm.status_filter,
      callback: function ($$v) {
        _vm.status_filter = $$v;
      },
      expression: "status_filter"
    }
  }, [_c('i-option', {
    attrs: {
      "value": "all"
    }
  }, [_vm._v(" Все статусы")]), _c('i-option', {
    attrs: {
      "value": "active"
    }
  }, [_vm._v(" Активные")]), _c('i-option', {
    attrs: {
      "value": "disabled"
    }
  }, [_vm._v(" Выключенные")])], 1), _c('b-overlay', {
    staticClass: "ml-auto d-inline-block",
    attrs: {
      "show": _vm.isLoading,
      "rounded": "sm",
      "opacity": "0.6",
      "spinner-small": "",
      "spinner-variant": "primary"
    }
  }, [_c('b-dropdown', {
    attrs: {
      "no-caret": "",
      "variant": "primary"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', [_c('img', {
          staticClass: "mr",
          attrs: {
            "src": "/img/icons/btn-plus.svg",
            "alt": ""
          }
        }), _vm._v(" Добавить кассу ")])];
      },
      proxy: true
    }])
  }, [_vm.cash_tags.dklink ? _c('b-dropdown-item', {
    on: {
      "click": _vm.createCash
    }
  }, [_vm._v(" DKLink FO ")]) : _vm._e(), _vm.cash_tags.frontol ? _c('b-dropdown-item', [_c('a', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.frontol-device-sidebar",
      modifiers: {
        "frontol-device-sidebar": true
      }
    }],
    staticClass: "dropdown-frontol"
  }, [_vm._v("Frontol")])]) : _vm._e(), _c('b-dropdown-item', {
    on: {
      "click": _vm.syncCash
    }
  }, [_vm._v("Обновить список касс Эвотор")])], 1)], 1)], 1), _c('b-card', {
    staticClass: "p-0",
    staticStyle: {
      "margin-top": "16px"
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('devices-table-filter', {
    staticStyle: {
      "margin": "16px",
      "width": "270px"
    },
    on: {
      "handler_pagination": _vm.search
    }
  }), _c('b-table', {
    staticClass: "b-table__wrapper",
    attrs: {
      "responsive": "",
      "fields": _vm.fields,
      "items": _vm.table_items
    },
    on: {
      "row-clicked": _vm.openDevice
    },
    scopedSlots: _vm._u([{
      key: "head(id)",
      fn: function () {
        return [_c('b-form-checkbox')];
      },
      proxy: true
    }, {
      key: "cell(id)",
      fn: function (data) {
        return [_c('b-form-checkbox', {
          attrs: {
            "value": data.value
          },
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v;
            },
            expression: "selected"
          }
        })];
      }
    }, {
      key: "cell(branch)",
      fn: function (data) {
        var _data$value;

        return [_vm._v(" " + _vm._s((_data$value = data.value) === null || _data$value === void 0 ? void 0 : _data$value.name) + " ")];
      }
    }, {
      key: "cell(type)",
      fn: function (data) {
        var _data$item, _data$item$integratio;

        return [_vm._v(" " + _vm._s(_vm.getTypeName((_data$item = data.item) === null || _data$item === void 0 ? void 0 : (_data$item$integratio = _data$item.integration) === null || _data$item$integratio === void 0 ? void 0 : _data$item$integratio.type)) + " ")];
      }
    }, {
      key: "cell(storage)",
      fn: function (data) {
        var _data$value2;

        return [_vm._v(" " + _vm._s((_data$value2 = data.value) === null || _data$value2 === void 0 ? void 0 : _data$value2.name) + " ")];
      }
    }, {
      key: "cell(status)",
      fn: function (data) {
        return [_c('b-badge', {
          attrs: {
            "variant": `${data.item.status ? 'success' : 'danger'}`
          }
        }, [_vm._v(" " + _vm._s(data.item.status ? 'Активна' : 'Выключена') + " ")])];
      }
    }])
  }), _c('table-navbar', {
    attrs: {
      "items": _vm.selected,
      "hide_print": "",
      "hide_remove": "",
      "items_name": "cash"
    },
    on: {
      "remove_items": _vm.remove_items,
      "cash_load": _vm.cashLoad,
      "cashDownload": _vm.cashDownloadModal
    }
  })], 1), _c('frontol-sidebar-device', {
    attrs: {
      "integration_id": _vm.cash_tags.frontol
    },
    on: {
      "refetch": function ($event) {
        return _vm.$apollo.queries.CashItems.refetch();
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }