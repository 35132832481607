<template>
  <div class="d-flex flex-column">
    <b-sidebar
      id="utm-device-sidebar"
      right
      shadow
      backdrop
    >
      <template #header="{ hide }">
        <div class="sidebar-title-text">Настройка УТМ</div>
        <img
          class="close-sidebar cursor"
          src="/img/integrations/icon-sidebar-close.svg"
          alt=""
          @click="hide"
        />
      </template>
      <div class="info-message">
        Универсальный транспортный модуль (УТМ) – специальное программное обеспечение, которое позволяет обмениваться
        информацией с Единой государственной автоматизированной информационной системой (ЕГАИС)
      </div>
      <e-select
        label="Магазин"
        disabled
        :options="getBranchList"
        :value="[this.currentBranch]"
        selected_type="text"
        class="mt-3"
      />
      <e-select
        v-model="current_utm.entity"
        label="Организация"
        :options="entities"
        search_in_drop
        selected_type="text"
        class="mt-3"
      >
        <template #custom_name="{ item }">
          <div class="entity w-100">
            <div class="name">
              {{ item?.name }}
            </div>
            <div class="inn_kpp">
              <p class="inn">ИНН: {{ item.inn ? item.inn : '-' }}</p>
              <p class="kpp">КПП: {{ item.kpp ? item.kpp : '-' }}</p>
            </div>
          </div>
        </template>
      </e-select>
      <e-input
        v-model="current_utm.host"
        label="ip"
        placeholder="http://192.168.1.10:8080"
        class="mt-3"
      />
      <e-select
        v-model="current_utm.storage"
        class="mt-3"
        :options="storage_list"
        selected_type="text"
        label="Место хранения"
      />
      <template #footer="{ hide }">
        <div class="b-action-buttons">
          <b-button
            variant="primary"
            class="cursor"
            @click="setUtm"
          >
            {{ 'Сохранить' }}
          </b-button>
          <b-button
            variant="outline-primary"
            class="cursor ml-3"
            @click="hide"
          >
            Отмена
          </b-button>
        </div>
      </template>
    </b-sidebar>
    <div class="d-flex">
      <b-button
        class="ml-auto"
        variant="primary"
        @click="createUtm"
      >
        <img
          src="/img/icons/btn-plus.svg"
          class="mr"
          alt=""
        />
        Добавить
      </b-button>
    </div>

    <b-card
      no-body
      class="p-0 mt-3"
    >
      <resizable-table
        table_name="utm-device"
        :inside_card="false"
        :busy="$apollo.queries.getUtmList.loading"
        empty_text="Пусто"
        :default_fields="fields"
        :items="items"
        @row-click="rowClicked"
      >
        <template #head_id> <e-checkbox /> </template>
        <template #body_branch="data">
          <div style="display: flex; justify-content: start">
            {{ data.value.name }}
          </div>
        </template>
        <template #body_entity="data">
          <entity-info :entity="data.value" />
        </template>
        <template #body_host="data">
          <div style="display: flex; justify-content: start">
            {{ data.value }}
          </div>
        </template>
        <template #body_id="{ item }">
          <div class="d-flex justify-content-center">
            <e-checkbox
              :checked="active(item?.id)"
              @click="select(item?.id)"
            />
          </div>
        </template>
      </resizable-table>
      <custom-navbar :items="selected">
        <template #buttons>
          <b-button
            variant="dark"
            @click="remove"
            >Удалить</b-button
          ></template
        >
      </custom-navbar>
    </b-card>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import ResizableTable from '@/components/ResizableTable'
  import EntityInfo from '@/views/payment/components/EntityInfo.vue'
  import CustomNavbar from '@/views/core/sidebar/components/CustomNavbar.vue'
  export default {
    name: 'UtmDevice',
    components: { CustomNavbar, EntityInfo, ResizableTable },
    apollo: {
      getUtmList: {
        query: require('../gql/getUtmList.gql'),
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
        variables() {
          return {
            input: {
              branch: this.currentBranch.id
            }
          }
        },
        result({ data }) {
          this.items = data?.getUtmList || []
        },
        error(err) {
          console.log(err)
        }
      },
      Entities: {
        query: require('../gql/getEntities.graphql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.entities = data.Entities || []
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },
      Storages: {
        query: require('../../operational-processes/gql/getStorages.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            branch: this.currentBranch.id
          }
        },
        result({ data }) {
          this.storage_list = data?.Storages ?? []
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },
    data() {
      return {
        items: [],
        fields: [
          {
            label: '',
            key: 'id',
            width: 60,
            checked: true
          },
          {
            label: 'Магазин',
            key: 'branch',
            width: 270,
            checked: true
          },
          {
            label: 'Организация',
            key: 'entity',
            width: 270,
            checked: true
          },
          {
            label: 'Хост',
            key: 'host',
            width: 270,
            checked: true
          }
        ],
        entities: [],
        selected: [],
        storage_list: [],
        current_utm: {
          id: null,
          entity: [],
          host: '',
          storage: [],
          branch: [this.currentBranch]
        }
      }
    },
    computed: {
      ...mapGetters({
        getBranchList: 'settings/getBranchList',
        currentBranch: 'settings/getCurrentBranch'
      })
    },

    methods: {
      ...mapActions({
        setBreadcrumbs: 'breadcrumbs/set_current'
      }),
      createUtm() {
        this.current_utm = {
          id: null,
          entity: [],
          host: '',
          storage: [],
          branch: [this.currentBranch]
        }
        this.$root.$emit('bv::toggle::collapse', 'utm-device-sidebar')
      },
      async setUtm() {
        await this.$apollo.mutate({
          mutation: require('../../integrations/gql/setEgaisUtm.graphql'),
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              id: this.current_utm?.id,
              storage: this.current_utm.storage[0]?.id,
              entity: this.current_utm.entity[0]?.id,
              branch: this.current_utm.branch[0]?.id,
              host: this.current_utm.host
            }
          }
        })
        await this.$apollo.queries.getUtmList.refetch()
        this.$root.$emit('bv::toggle::collapse', 'utm-device-sidebar')
      },
      rowClicked(item) {
        this.current_utm = {
          id: item.id,
          entity: [item.entity],
          host: item.host,
          storage: [{ id: item.storage }],
          branch: [this.currentBranch]
        }
        this.$root.$emit('bv::toggle::collapse', 'utm-device-sidebar')
      },
      active(item) {
        return this.selected.includes(item)
      },
      select(val) {
        if (!this.selected.includes(val)) {
          this.selected.push(val)
        } else {
          this.selected = this.selected.filter((obj) => obj !== val)
        }
      },
      async remove() {
        await this.$apollo.mutate({
          mutation: require('../../integrations/gql/deleteUtm.graphql'),
          fetchPolicy: 'no-cache',
          variables: {
            ids: this.selected
          }
        })
        await this.$apollo.queries.getUtmList.refetch()
        this.selected = []
      }
    }
  }
</script>

<style lang="scss" scoped>
  .sidebar-title-text {
    color: var(--text-primary-gray-700, #313131);
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .info-message {
    border-radius: 4px;
    background: #eef9ff;
    border-left: 3px #99daff solid;
    padding: 16px;
  }
  ::v-deep() {
    #utm-device-sidebar {
      width: 680px !important;

      .b-sidebar-header {
        position: relative;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        height: 85px;
      }

      .b-sidebar-footer {
        position: relative;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        height: 65px;
        display: flex;
      }

      .b-sidebar-body {
        background: #fff;
        padding: 22px;

        .sidebar-block {
          .block-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 16px;
            color: #313131;
            margin-bottom: 16px;
          }

          a {
            border-bottom: 1px solid #0066ff;
            font-size: 16px;
          }
        }

        .info {
          &__detail {
            a {
              color: #313131;
              font-weight: 600;
              border-bottom: 1px solid #313131;
            }
          }
        }

        .token {
          form {
            margin-top: 7px;

            input {
              height: 42px !important;
              border: 1px solid #bcbcbc;
              border-radius: 2px;
              font-size: 16px;
              color: #313131;
            }
          }
        }

        .b-select {
          border: 1px solid #e1e1e1;
          border-radius: 4px;

          &__title {
            border-bottom: 1px solid #e1e1e1;
            display: flex;

            .item {
              width: 50%;
              padding: 8px 0;
              text-align: center;
            }
          }

          &__item {
            display: flex;
            padding: 16px;
            justify-content: space-between;

            input,
            .ivu-select {
              width: 282px;
            }
          }
        }
      }
    }
  }
  .entity {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    p {
      margin-bottom: 0;
    }
    .name {
      display: flex;
      float: left;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      //color: #313131;
    }
    .inn_kpp {
      display: flex;
      .inn {
        margin-right: 5px;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #888888;
      }
    }
  }
  #utm-device-sidebar {
    width: 680px !important;

    .b-sidebar-header {
      img.close-sidebar {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 22px;
      }
    }

    .b-sidebar-footer {
      .b-action-buttons {
        padding: 0 22px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }

    .b-sidebar-body {
      .sidebar-block {
        border-bottom: 1px solid #e1e1e1;
        padding: 22px;

        p {
          font-size: 16px;
          line-height: 24px;
          color: #000000;
        }
      }

      .info {
        font-size: 16px;
        line-height: 24px;
        color: #000000;

        &__detail {
          background: #e6f0ff;
          border-radius: 4px;
          margin-top: 22px;
          border-left: 3px solid #b9d6ff;
          padding: 15px;

          img {
            margin-right: 15px;
          }
        }
      }

      .token {
        .text {
          margin-top: 16px;

          img {
            margin-left: 5px;
          }
        }
      }

      .b-select {
        &__title {
          .item {
            &:nth-child(2) {
              border-left: 1px solid #e1e1e1;
            }
          }
        }
      }
    }
  }
</style>
