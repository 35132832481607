<template>
  <div
    id="printer-tab"
    class="printer-devices"
  >
    <div class="d-flex printer-tab-filter-block">
      <i-select
        v-model="printer_filter"
        placeholder="Все кассы"
        style="width: 116px; margin-right: 15px"
      >
        <i-option value="all">Все принтеры</i-option>
        <i-option value="bixolon">Bixolon</i-option>
      </i-select>
      <i-select
        v-model="status_filter"
        placeholder="Все статусы"
        style="width: 116px"
      >
        <i-option value="all"> Все статусы</i-option>
        <i-option value="active">Включенные</i-option>
        <i-option value="disabled">Выключенные</i-option>
      </i-select>
      <b-overlay
        class="ml-auto d-inline-block"
        :show="isLoading"
        rounded="sm"
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
      >
        <div>
          <b-dropdown
            id="dropdown-left"
            class="m-md-2"
            variant="primary"
            menu-class="w-100"
          >
            <template #button-content>
              <img
                style="margin-right: 12px"
                src="/img/integrations/plus-printer.svg"
                alt="plus"
              />
              Добавить принтер
            </template>
            <b-dropdown-item>
              <a
                v-b-toggle.printer-sidebar
                class="dropdown-item-link"
                >Bixolon</a
              >
            </b-dropdown-item>
            <b-dropdown-item>
              <div
                class="dropdown-item-plus-integration"
                @click="goToIntegrations"
              >
                <img
                  style="margin-right: 12px"
                  src="/img/integrations/printer-plus-integration.svg"
                  alt="plus"
                />
                Новая интеграция
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </b-overlay>
    </div>

    <b-card
      style="margin-top: 16px; display: flex; flex: 1"
      no-body
      class="p-0"
    >
      <devices-table-filter
        style="margin: 16px; width: 270px"
        @handler_pagination="search"
      />

      <resizable-table
        table_name="printers"
        class="b-table__wrapper"
        :default_fields="fields"
        :busy="isLoading"
        :items="table_items"
        @row-click="openPrinterDevice"
      >
        <template #head_id>
          <b-form-checkbox v-model="select_all" />
        </template>

        <template #body_id="data">
          <!-- <div 
                        :class="{ success: data?.item?.counts?.active_items === 0 }"
                        class="b-statuses">
                    </div> -->
          <div @click.stop>
            <b-form-checkbox
              v-model="selected"
              :value="data?.value"
            />
          </div>
        </template>

        <template #body_branch="{ item }">
          <div class="printer-devices__printer-row">
            <div>
              {{ item.branch.name }}
            </div>
            <div class="printer-devices__branch-description">
              <p class="printer-devices__branch-description-text">{{ item.branch.address.city }}</p>
              <p class="printer-devices__branch-description-text">{{ item.branch.address.street }}</p>
              <p class="printer-devices__branch-description-text">{{ item.branch.address.apartment }}</p>
            </div>
          </div>
        </template>

        <template #body_model="{ item }">
          <div class="printer-devices__printer-row">
            {{ item.name }}
          </div>
        </template>

        <!-- <template #body_name>
                    {{ item.name}}
                </template> -->

        <template #body_status="{ item }">
          <div
            :class="{
              'printer-active': item.active,
              'printer-no-active': !item.active
            }"
          >
            {{ item.active ? 'Включен' : 'Выключен' }}
          </div>
        </template>

        <template #body_state="{ item }">
          <div
            :class="{
              'printer-status-ok': item.status,
              'printer-status-error': !item.status
            }"
          >
            {{ item.status ? 'Успешно' : 'Ошибка' }}
            <img
              v-if="!item.status"
              class="printer-status-error-img"
              src="/img/integrations/printer-state.svg"
              alt=""
            />
          </div>
        </template>
      </resizable-table>

      <table-navbar
        :items="selected"
        hide_print
        :scale_active="selectedElement.active"
        :clear_selected="clearAllSelected"
        items_name="printers"
        @remove_items="removePrinter"
      />
    </b-card>
    <printer-sidebar @refetch="$apollo.queries.Devices.refetch()" />
  </div>
</template>

<script>
  import TableNavbar from '@/views/shared/components/table/TableNavbar'
  import { mapGetters } from 'vuex'
  export default {
    name: 'PrinterDevices',
    components: {
      TableNavbar
    },
    apollo: {
      Devices: {
        query: require('../../gql/PrinterDevicesList.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            input: {
              branch: this.getCurrentBranch?.id,
              tags: ['printer']
            }
          }
        },
        result({ data }) {
          this.items = data?.Devices ?? []
          this.isLoading = false
        },
        error() {
          this.isLoading = false
        }
      }
    },
    data() {
      return {
        selected: [],
        isLoading: true,
        search_str: '',
        status_filter: 'all',
        printer_filter: 'all',
        items: [],
        select_all: false,
        fields: [
          { key: 'id', label: '', width: 60, checked: true },
          { key: 'model', label: 'Модель', width: 250, checked: true },
          // { key: 'name', label: 'Название', checked: true },
          { key: 'branch', label: 'Магазин', width: 350, checked: true },
          { key: 'status', label: 'Статус', width: 150, checked: true },
          { key: 'state', label: 'Состояние', width: 150, checked: true }
        ]
      }
    },

    watch: {
      select_all(value) {
        if (value) {
          this.selected = this.items.map((el) => el.id)
        } else {
          this.selected = []
        }
      }
    },

    beforeCreate() {
      this.$options.components.DevicesTableFilter =
        require('@/views/settings/components/devices/DevicesTableFilter').default
      this.$options.components.PrinterSidebar = require('@/views/settings/components/devices/PrinterSidebar').default
    },

    methods: {
      search(data) {
        this.search_str = data
      },

      clearAllSelected() {
        this.select_all = false
      },

      removeLists() {
        this.selected = []
      },

      openPrinterDevice(item) {
        this.$router.push({
          name: 'devices.printer',
          params: { id: item?.id, name: item?.type, visible: item?.active }
        })
      },

      goToIntegrations() {
        this.$router.push({ name: 'integrations.list' })
      },

      removePrinter(printers) {
        if (printers.length > 0) {
          this.$apollo
            .mutate({
              mutation: require('../../gql/RemovePrinterDevice.gql'),
              variables: {
                ids: printers
              }
            })
            .then(() => {
              this.$apollo.queries.Devices.refetch()
              this.$noty.show(`Удаление`)
              this.selected = []
              this.select_all = false
            })
            .catch((err) => {
              console.error('error: ', err)
              this.$noty.show(`Что-то пошло не так. Попробуйте еще раз`)
            })
        }
      }
    },

    computed: {
      ...mapGetters({
        getCurrentBranch: 'settings/getCurrentBranch'
      }),

      selectedElement() {
        return this.items?.find((el) => el?.id === this.selected[0]) || {}
      },

      table_items() {
        return this.items.filter((el) => {
          let flag = false
          if (this.printer_filter === 'all') flag = true
          if (this.printer_filter !== 'all' && el?.type === this.printer_filter) flag = true

          if (this.status_filter === 'active') flag = flag && el.active === true
          if (this.status_filter === 'disabled') flag = flag && el.active === false

          flag = flag && (el.name?.includes(this.search_str) || ('' + el.number)?.includes(this.search_str))

          return flag
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    #printer-tab {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .printer-devices {
      &__button-add-printer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__b-table__wrapper {
        width: 100%;
      }
    }

    thead tr {
      height: 53px;
    }
  }

  #printer-tab {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .printer-devices {
    height: 100%;

    &__printer-row {
      text-align: left;
    }

    &__branch-description {
      display: flex;
      margin: 0px 5px 0px 0px;
    }

    &__branch-description-text {
      margin: 0px 5px 0px 0px;
      text-align: left;
      color: var(--text-secondary-gray-400, #888);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }
  }

  .printer-active {
    width: 85px;
    border-radius: 3px;
    color: #00cb91;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    background-color: #ebf9f5;
  }

  .printer-no-active {
    width: 85px;
    border-radius: 3px;
    color: var(--red-alt-600, #e53835);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    background: var(--red-alt-150, #ffebee);
  }

  .printer-status-ok {
    border-radius: 3px;
    border: 1px solid var(--text-successful-green-400, #00cb91);
    color: #00cb91;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    width: 90px;
  }

  .printer-status-error {
    border-radius: 3px;
    border: 1px solid var(--text-alert-red-400, #e53835);
    color: var(--red-alt-600, #e53835);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    width: 105px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .printer-status-error-img {
    margin-left: 6px;
  }

  .dropdown-item-link {
    padding-left: 0 !important;
    padding-right: 70% !important;
  }

  .dropdown-item-plus-integration {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-primary-gray-700, #313131);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .printer-tab-filter-block {
    z-index: 333;
  }
</style>
