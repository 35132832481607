var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "devices-block"
    }
  }, [_c('b-tabs', {
    attrs: {
      "pills": "",
      "card": "",
      "nav-wrapper-class": "tags-header",
      "active-nav-item-class": "tag-item-active"
    },
    model: {
      value: _vm.activeTab,
      callback: function ($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c('b-tab', {
    attrs: {
      "title-link-class": "tag-item",
      "title": "Кассы"
    }
  }, [_c('cash-devices')], 1), _c('b-tab', {
    attrs: {
      "title-link-class": "tag-item",
      "title": "Списки загрузки весов"
    }
  }, [_vm.activeTab === 1 ? _c('scales-devices') : _vm._e()], 1), _c('b-tab', {
    attrs: {
      "title-link-class": "tag-item",
      "title": "Принтеры"
    }
  }, [_vm.activeTab === 2 ? _c('printer-devices') : _vm._e()], 1), _c('b-tab', {
    attrs: {
      "title-link-class": "tag-item",
      "title": "УТМ"
    }
  }, [_vm.activeTab === 3 ? _c('utm-device') : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }