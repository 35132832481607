var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "printer-inner"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "printer-sidebar",
      "title": "Bixolon",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref) {
        var hide = _ref.hide;
        return [_vm._v(" Bixolon "), _c('img', {
          staticClass: "close-sidebar cursor",
          attrs: {
            "src": "/img/integrations/icon-sidebar-close.svg",
            "alt": ""
          },
          on: {
            "click": hide
          }
        })];
      }
    }, {
      key: "footer",
      fn: function (_ref2) {
        var hide = _ref2.hide;
        return [_c('div', {
          staticClass: "b-action-buttons"
        }, [_c('b-button', {
          staticClass: "cursor",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.setPrinter(hide);
            }
          }
        }, [_vm._v(" Продолжить ")]), _c('b-button', {
          staticClass: "cursor ml-3",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function ($event) {
              return _vm.cancellationPrinterSidebar(hide);
            }
          }
        }, [_vm._v(" Отмена ")])], 1)];
      }
    }])
  }, [[_c('div', {
    staticClass: "sidebar-block info"
  }, [_c('p', {
    staticClass: "sidebar-block__description"
  }, [_vm._v("Специализированный принтер для печати ценников")]), _c('div', {
    staticClass: "info__detail"
  }, [_c('img', {
    attrs: {
      "src": "/img/integrations/icon-info-blue.svg",
      "alt": ""
    }
  }), _c('a', {
    attrs: {
      "target": "_blank"
    }
  }, [_vm._v("Подробная справка")]), _vm._v(" по интеграции ")])]), _c('form', {
    staticClass: "form-block-bixolon"
  }, [_c('p', {
    staticClass: "form-block-bixolon__label"
  }, [_vm._v("Модель")]), _c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('e-select', {
    staticClass: "e-select-el",
    attrs: {
      "options": _vm.optionModelPrinter,
      "serchable": "",
      "max_selected_count": 1,
      "placeholder": "Выбeрите",
      "select_name": "entity_select"
    },
    scopedSlots: _vm._u([{
      key: "custom_name",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('div', [_c('p', {
          staticClass: "e-select-el__option-name"
        }, [_vm._v(_vm._s(item.name))])])];
      }
    }]),
    model: {
      value: _vm.modelPrinter,
      callback: function ($$v) {
        _vm.modelPrinter = $$v;
      },
      expression: "modelPrinter"
    }
  })], 1), _c('p', {
    staticClass: "form-block-bixolon__label"
  }, [_vm._v("Название")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.namePrinter,
      expression: "namePrinter"
    }],
    staticClass: "form-block-bixolon__input",
    attrs: {
      "type": "text",
      "placeholder": "Введите"
    },
    domProps: {
      "value": _vm.namePrinter
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.namePrinter = $event.target.value;
      }
    }
  }), _c('p', {
    staticClass: "form-block-bixolon__label"
  }, [_vm._v("Магазин")]), _c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('e-select', {
    staticClass: "e-select-el",
    attrs: {
      "options": _vm.getBranchList,
      "serchable": "",
      "max_selected_count": 1,
      "placeholder": "Выбeрите",
      "select_name": "entity_select"
    },
    scopedSlots: _vm._u([{
      key: "custom_name",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('div', [_c('p', {
          staticClass: "e-select-el__option-name"
        }, [_vm._v(_vm._s(item.name))]), _c('div', {
          staticClass: "e-select-el__option-description-block"
        }, [_c('p', {
          staticClass: "e-select-el__option-description"
        }, [_vm._v(_vm._s(item.address.city))]), _c('p', {
          staticClass: "e-select-el__option-description"
        }, [_vm._v(_vm._s(item.address.street))]), _c('p', {
          staticClass: "e-select-el__option-description"
        }, [_vm._v(_vm._s(item.address.apartment))])])])];
      }
    }]),
    model: {
      value: _vm.nameShop,
      callback: function ($$v) {
        _vm.nameShop = $$v;
      },
      expression: "nameShop"
    }
  })], 1)])], _vm.errorInputText ? _c('div', {
    staticClass: "sidebar-block__error"
  }, [_vm._v(" Все поля должны быть заполнены! ")]) : _vm._e()], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }