var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "printer-devices",
    attrs: {
      "id": "printer-tab"
    }
  }, [_c('div', {
    staticClass: "d-flex printer-tab-filter-block"
  }, [_c('i-select', {
    staticStyle: {
      "width": "116px",
      "margin-right": "15px"
    },
    attrs: {
      "placeholder": "Все кассы"
    },
    model: {
      value: _vm.printer_filter,
      callback: function ($$v) {
        _vm.printer_filter = $$v;
      },
      expression: "printer_filter"
    }
  }, [_c('i-option', {
    attrs: {
      "value": "all"
    }
  }, [_vm._v("Все принтеры")]), _c('i-option', {
    attrs: {
      "value": "bixolon"
    }
  }, [_vm._v("Bixolon")])], 1), _c('i-select', {
    staticStyle: {
      "width": "116px"
    },
    attrs: {
      "placeholder": "Все статусы"
    },
    model: {
      value: _vm.status_filter,
      callback: function ($$v) {
        _vm.status_filter = $$v;
      },
      expression: "status_filter"
    }
  }, [_c('i-option', {
    attrs: {
      "value": "all"
    }
  }, [_vm._v(" Все статусы")]), _c('i-option', {
    attrs: {
      "value": "active"
    }
  }, [_vm._v("Включенные")]), _c('i-option', {
    attrs: {
      "value": "disabled"
    }
  }, [_vm._v("Выключенные")])], 1), _c('b-overlay', {
    staticClass: "ml-auto d-inline-block",
    attrs: {
      "show": _vm.isLoading,
      "rounded": "sm",
      "opacity": "0.6",
      "spinner-small": "",
      "spinner-variant": "primary"
    }
  }, [_c('div', [_c('b-dropdown', {
    staticClass: "m-md-2",
    attrs: {
      "id": "dropdown-left",
      "variant": "primary",
      "menu-class": "w-100"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('img', {
          staticStyle: {
            "margin-right": "12px"
          },
          attrs: {
            "src": "/img/integrations/plus-printer.svg",
            "alt": "plus"
          }
        }), _vm._v(" Добавить принтер ")];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('a', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.printer-sidebar",
      modifiers: {
        "printer-sidebar": true
      }
    }],
    staticClass: "dropdown-item-link"
  }, [_vm._v("Bixolon")])]), _c('b-dropdown-item', [_c('div', {
    staticClass: "dropdown-item-plus-integration",
    on: {
      "click": _vm.goToIntegrations
    }
  }, [_c('img', {
    staticStyle: {
      "margin-right": "12px"
    },
    attrs: {
      "src": "/img/integrations/printer-plus-integration.svg",
      "alt": "plus"
    }
  }), _vm._v(" Новая интеграция ")])])], 1)], 1)])], 1), _c('b-card', {
    staticClass: "p-0",
    staticStyle: {
      "margin-top": "16px",
      "display": "flex",
      "flex": "1"
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('devices-table-filter', {
    staticStyle: {
      "margin": "16px",
      "width": "270px"
    },
    on: {
      "handler_pagination": _vm.search
    }
  }), _c('resizable-table', {
    staticClass: "b-table__wrapper",
    attrs: {
      "table_name": "printers",
      "default_fields": _vm.fields,
      "busy": _vm.isLoading,
      "items": _vm.table_items
    },
    on: {
      "row-click": _vm.openPrinterDevice
    },
    scopedSlots: _vm._u([{
      key: "head_id",
      fn: function () {
        return [_c('b-form-checkbox', {
          model: {
            value: _vm.select_all,
            callback: function ($$v) {
              _vm.select_all = $$v;
            },
            expression: "select_all"
          }
        })];
      },
      proxy: true
    }, {
      key: "body_id",
      fn: function (data) {
        return [_c('div', {
          on: {
            "click": function ($event) {
              $event.stopPropagation();
            }
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "value": data === null || data === void 0 ? void 0 : data.value
          },
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v;
            },
            expression: "selected"
          }
        })], 1)];
      }
    }, {
      key: "body_branch",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "printer-devices__printer-row"
        }, [_c('div', [_vm._v(" " + _vm._s(item.branch.name) + " ")]), _c('div', {
          staticClass: "printer-devices__branch-description"
        }, [_c('p', {
          staticClass: "printer-devices__branch-description-text"
        }, [_vm._v(_vm._s(item.branch.address.city))]), _c('p', {
          staticClass: "printer-devices__branch-description-text"
        }, [_vm._v(_vm._s(item.branch.address.street))]), _c('p', {
          staticClass: "printer-devices__branch-description-text"
        }, [_vm._v(_vm._s(item.branch.address.apartment))])])])];
      }
    }, {
      key: "body_model",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "printer-devices__printer-row"
        }, [_vm._v(" " + _vm._s(item.name) + " ")])];
      }
    }, {
      key: "body_status",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          class: {
            'printer-active': item.active,
            'printer-no-active': !item.active
          }
        }, [_vm._v(" " + _vm._s(item.active ? 'Включен' : 'Выключен') + " ")])];
      }
    }, {
      key: "body_state",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          class: {
            'printer-status-ok': item.status,
            'printer-status-error': !item.status
          }
        }, [_vm._v(" " + _vm._s(item.status ? 'Успешно' : 'Ошибка') + " "), !item.status ? _c('img', {
          staticClass: "printer-status-error-img",
          attrs: {
            "src": "/img/integrations/printer-state.svg",
            "alt": ""
          }
        }) : _vm._e()])];
      }
    }])
  }), _c('table-navbar', {
    attrs: {
      "items": _vm.selected,
      "hide_print": "",
      "scale_active": _vm.selectedElement.active,
      "clear_selected": _vm.clearAllSelected,
      "items_name": "printers"
    },
    on: {
      "remove_items": _vm.removePrinter
    }
  })], 1), _c('printer-sidebar', {
    on: {
      "refetch": function ($event) {
        return _vm.$apollo.queries.Devices.refetch();
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }