var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "scales-tab"
    }
  }, [_c('plu-product-list-modal', {
    attrs: {
      "list_id": _vm.list_id,
      "list_name": _vm.list_name
    },
    on: {
      "close": _vm.close
    }
  }), _c('div', {
    staticClass: "d-flex"
  }, [_c('b-overlay', {
    staticClass: "ml-auto d-inline-block",
    attrs: {
      "show": _vm.isLoading,
      "rounded": "sm",
      "opacity": "0.6",
      "spinner-small": "",
      "spinner-variant": "primary"
    }
  }, [_c('e-button', {
    attrs: {
      "size": "m",
      "variant": "primary",
      "plus": ""
    },
    on: {
      "click": _vm.addScale
    }
  }, [_vm._v(" Добавить список ")])], 1)], 1), _c('b-card', {
    staticClass: "p-0",
    staticStyle: {
      "margin-top": "16px",
      "display": "flex",
      "flex": "1"
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('devices-table-filter', {
    staticStyle: {
      "margin": "16px",
      "width": "270px"
    },
    on: {
      "handler_pagination": _vm.search
    }
  }), _c('resizable-table', {
    staticClass: "b-table__wrapper",
    attrs: {
      "table_name": "scales",
      "default_fields": _vm.fields,
      "busy": _vm.isLoading,
      "items": _vm.items
    },
    scopedSlots: _vm._u([{
      key: "head_id",
      fn: function () {
        return [_c('e-checkbox', {
          attrs: {
            "checked": _vm.select_all
          },
          on: {
            "click": _vm.changeSelectAll
          }
        })];
      },
      proxy: true
    }, {
      key: "body_id",
      fn: function (data) {
        var _data$item, _data$item$counts, _vm$selected;

        return [_c('div', {
          staticClass: "b-statuses",
          class: {
            success: (data === null || data === void 0 ? void 0 : (_data$item = data.item) === null || _data$item === void 0 ? void 0 : (_data$item$counts = _data$item.counts) === null || _data$item$counts === void 0 ? void 0 : _data$item$counts.active_items) === 0
          }
        }), _c('b-form-checkbox', {
          attrs: {
            "checked": (_vm$selected = _vm.selected) === null || _vm$selected === void 0 ? void 0 : _vm$selected.some(function (el) {
              var _data$item2;

              return el === (data === null || data === void 0 ? void 0 : (_data$item2 = data.item) === null || _data$item2 === void 0 ? void 0 : _data$item2.id);
            })
          },
          on: {
            "change": function (val) {
              var _data$item3;

              return _vm.addSelected(val, data === null || data === void 0 ? void 0 : (_data$item3 = data.item) === null || _data$item3 === void 0 ? void 0 : _data$item3.id);
            }
          }
        })];
      }
    }, {
      key: "body_branch",
      fn: function () {
        var _vm$currentBranch$nam;

        return [_vm._v(" " + _vm._s((_vm$currentBranch$nam = _vm.currentBranch.name) !== null && _vm$currentBranch$nam !== void 0 ? _vm$currentBranch$nam : '-') + " ")];
      },
      proxy: true
    }, {
      key: "head_settings",
      fn: function () {
        return [_c('div', {
          staticClass: "settings-block__head"
        }, [_c('img', {
          staticStyle: {
            "opacity": "0.6"
          },
          attrs: {
            "src": "/img/icons/settings2.svg",
            "alt": "settings"
          }
        })])];
      },
      proxy: true
    }, {
      key: "body_settings",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "settings-block"
        }, [_c('div', {
          staticClass: "button"
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/settings2.svg",
            "alt": "settings"
          },
          on: {
            "click": function ($event) {
              return _vm.clickSettings(item);
            }
          }
        })])])];
      }
    }, {
      key: "body_last_online",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(_vm.formatDate(data.value)) + " ")];
      }
    }, {
      key: "body_status",
      fn: function (data) {
        var _data$item4, _data$item5, _data$item5$counts, _data$item6, _data$item7, _data$item7$counts, _data$item8, _data$item8$counts, _data$item9, _data$item10, _data$item10$counts, _data$item11, _data$item11$counts;

        return [_c('b-badge', {
          attrs: {
            "variant": `${!(data !== null && data !== void 0 && (_data$item4 = data.item) !== null && _data$item4 !== void 0 && _data$item4.devices) ? 'outline-secondary' : (data === null || data === void 0 ? void 0 : (_data$item5 = data.item) === null || _data$item5 === void 0 ? void 0 : (_data$item5$counts = _data$item5.counts) === null || _data$item5$counts === void 0 ? void 0 : _data$item5$counts.active_items) === 0 ? 'outline-success' : 'outline-danger'}`
          }
        }, [_c('div', {
          staticClass: "bage-status"
        }, [!(data !== null && data !== void 0 && (_data$item6 = data.item) !== null && _data$item6 !== void 0 && _data$item6.devices) ? _c('span', [_vm._v("Нет весов")]) : _c('span', [_vm._v(" " + _vm._s((data === null || data === void 0 ? void 0 : (_data$item7 = data.item) === null || _data$item7 === void 0 ? void 0 : (_data$item7$counts = _data$item7.counts) === null || _data$item7$counts === void 0 ? void 0 : _data$item7$counts.active_items) === 0 ? 'Связть есть' : 'Нет связи') + " ")]), (data === null || data === void 0 ? void 0 : (_data$item8 = data.item) === null || _data$item8 === void 0 ? void 0 : (_data$item8$counts = _data$item8.counts) === null || _data$item8$counts === void 0 ? void 0 : _data$item8$counts.active_items) !== 0 && data !== null && data !== void 0 && (_data$item9 = data.item) !== null && _data$item9 !== void 0 && _data$item9.devices ? _c('span', {
          staticClass: "numbers"
        }, [_vm._v(" " + _vm._s(data === null || data === void 0 ? void 0 : (_data$item10 = data.item) === null || _data$item10 === void 0 ? void 0 : (_data$item10$counts = _data$item10.counts) === null || _data$item10$counts === void 0 ? void 0 : _data$item10$counts.active_items) + "/" + _vm._s(data === null || data === void 0 ? void 0 : (_data$item11 = data.item) === null || _data$item11 === void 0 ? void 0 : (_data$item11$counts = _data$item11.counts) === null || _data$item11$counts === void 0 ? void 0 : _data$item11$counts.count) + " ")]) : _vm._e()])])];
      }
    }, {
      key: "body_products",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "product_list"
        }, [_c('list-svg'), _c('div', {
          staticClass: "text-line",
          on: {
            "click": [function ($event) {
              return _vm.product_list(item);
            }, function ($event) {
              $event.stopPropagation();
            }]
          }
        }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1)];
      }
    }])
  }), _c('table-navbar', {
    attrs: {
      "items": _vm.selected,
      "hide_print": "",
      "scale_active": _vm.selectedElement.active,
      "clear_selected": _vm.clearSelected,
      "items_name": "scales"
    },
    on: {
      "remove_items": _vm.removeLists,
      "scalesLoad": _vm.scalesLoad
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }