<template>
  <b-sidebar
    id="frontol-device-sidebar"
    title="Frontol 6"
    right
    backdrop
    shadow
    @shown="shownSidebar"
    @hidden="closeSidebar"
  >
    <print-group-setting @close_switch="isSwitchActive = false" />
    <frontol-boot-options-modal
      :cash_device="dataOfTheCreatedCheckout"
      @refetch="refetch"
    />

    <template #header="{ hide }">
      Frontol 6
      <div class="header-left-block">
        <img
          class="close-sidebar cursor"
          src="/img/integrations/icon-sidebar-close.svg"
          alt=""
          @click="hide"
        />
      </div>
    </template>

    <div class="sidebar-block info">
      <p>
        Профессиональное программное обеспечение для автоматизации рабочих мест кассира предприятий розничной торговли и
        общественного питания любых форматов и размеров
      </p>
      <div class="info__detail">
        <img
          src="/img/integrations/icon-info-blue.svg"
          alt=""
        />
        <router-link to="#">Подробная справка</router-link>
        по интеграции
      </div>
    </div>

    <template>
      <div class="frontol-content">
        <h3 class="frontol-content__title">Укажите параметры обмена с кассовым ПО</h3>

        <form class="form-block-frontol">
          <label class="form-block-frontol__label">Номер кассы</label>
          <e-input
            v-model="numberCash"
            class="form-block-frontol__input"
            placeholder="Введите номер"
            type="number"
          />

          <label class="form-block-frontol__label">Наименование</label>
          <e-input
            v-model="nameCash"
            class="form-block-frontol__input"
            placeholder="Введите имя"
          />

          <label class="form-block-frontol__label">Магазин</label>
          <div style="width: 100%">
            <e-select
              v-model="shopName"
              class="e-select-el"
              :options="getBranchList"
              serchable
              :max_selected_count="1"
              placeholder="Выберите магазин"
              select_name="entity_select"
            >
              <template #custom_name="{ item }">
                <div>
                  <p class="e-select-el__option-name">{{ item.name }}</p>
                  <div class="e-select-el__option-description-block">
                    <p class="e-select-el__option-description">{{ item.address.city }}</p>
                    <p class="e-select-el__option-description">{{ item.address.street }}</p>
                    <p class="e-select-el__option-description">{{ item.address.apartment }}</p>
                  </div>
                </div>
              </template>
            </e-select>
          </div>

          <label class="form-block-frontol__label">Место хранения</label>
          <div style="width: 100%">
            <e-select
              v-model="storage"
              class="e-select-el"
              :options="storageList"
              serchable
              :max_selected_count="1"
              placeholder="Выберите место хранения"
              select_name="entity_select"
            >
              <template #custom_name="{ item }">
                <div>
                  <p class="e-select-el__option-name">{{ item.name }}</p>
                </div>
              </template>
            </e-select>
          </div>

          <label class="form-block-frontol__label">Организция</label>
          <div style="width: 100%">
            <e-select
              v-model="entity"
              class="e-select-el"
              :options="entities"
              serchable
              :max_selected_count="1"
              placeholder="Выберите организацию"
              select_name="entity_select"
            >
              <template #custom_name="{ item }">
                <div>
                  <p class="e-select-el__option-name">{{ item.name }}</p>
                </div>
              </template>
            </e-select>
          </div>

          <label class="form-block-frontol__label">
            Каталог загрузки
            <div class="form-block-frontol__lable-img-block">
              <img
                class="cursor"
                style="margin-left: 3px"
                src="/img/integrations/frontol/Info.svg"
                alt=""
                @mouseenter="showLabellModalInPath"
                @mouseleave="closeLabellModalInPath"
              />
              <label-input-modal :is_show="isShowModalInPath">
                <p class="text-label-input-modal">Путь до места загрузки данных на кассу</p>
              </label-input-modal>
            </div>
          </label>
          <e-input
            v-model="catalogInPath"
            class="form-block-frontol__input"
            placeholder="C:\in_path\"
            :error="errorCatalogInPath"
          />

          <label class="form-block-frontol__label">
            Каталог выгрузки
            <div class="form-block-frontol__lable-img-block">
              <img
                class="cursor"
                style="margin-left: 3px"
                src="/img/integrations/frontol/Info.svg"
                alt=""
                @mouseenter="showLabellModalOutPath"
                @mouseleave="closeLabellModalOutPath"
              />
              <label-input-modal :is_show="isShowModalOutPath">
                <p class="text-label-input-modal">Путь до места выгрузки данных с кассы</p>
              </label-input-modal>
            </div>
          </label>
          <e-input
            v-model="catalogOutPath"
            class="form-block-frontol__input"
            placeholder="C:\out_path\"
            :error="errorCatalogOutPath"
          />

          <div class="form-block-frontol__block-chekbox">
            <b-form-checkbox
              :checked="isSwitchActive"
              name="check-button"
              switch
              @change="changeSwitchActive"
            />
            <label style="margin-bottom: 0">
              Использовать Группы печати
              <img
                class="cursor"
                style="margin-left: 6px"
                src="/img/integrations/frontol/settings.svg"
                alt=""
                @click="$bvModal.show('print-group-setting')"
              />
              <img
                class="cursor"
                style="margin-left: 6px"
                src="/img/integrations/frontol/Info.svg"
                alt=""
                @mouseenter="showLabellModalGroupPrint"
                @mouseleave="closeLabellModalGroupPrint"
              />
              <label-input-modal :is_show="isShowModalGroupPrint">
                <p class="text-label-input-modal">
                  Механизм применяется, когда требуется разделить продажу товара по разным ККМ
                </p>
              </label-input-modal>
            </label>
          </div>
        </form>
      </div>
    </template>

    <template #footer="{ hide }">
      <div class="b-action-buttons">
        <b-button
          variant="primary"
          class="cursor"
          @click="setFrontolDevice(hide)"
        >
          Подключить
        </b-button>
        <b-button
          variant="outline-primary"
          class="cursor ml-3"
          @click="closeSidebar(hide)"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
  import FrontolBootOptionsModal from '../../modal/FrontolBootOptionsModal.vue'
  import PrintGroupSetting from '../../modal/PrintGroupSetting.vue'
  import LabelInputModal from '../../modal/LabelInputModal.vue'
  import { mapGetters } from 'vuex'
  export default {
    name: 'FrontolSidebarDevice',
    components: { PrintGroupSetting, LabelInputModal, FrontolBootOptionsModal },

    apollo: {
      Storages: {
        query: require('../../gql/getStorages.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            branch: this.getCurrentBranch.id
          }
        },
        result({ data }) {
          this.storageList = data?.Storages || []
        },
        error(err) {
          console.log(err)
        }
      }
    },

    props: {
      integration_id: {
        type: String,
        default: ''
      }
    },

    data() {
      return {
        entity: [],
        storage: [],
        entities: [],
        shopName: [],
        nameCash: '',
        numberCash: '',
        storageList: [],
        cashDeviceId: '',
        catalogInPath: '',
        catalogOutPath: '',
        isSwitchActive: false,
        isShowModalInPath: false,
        isShowModalOutPath: false,
        errorCatalogInPath: false,
        errorCatalogOutPath: false,
        isShowModalGroupPrint: false,
        dataOfTheCreatedCheckout: {},
        isShowModalOperationalSummaryCatalog: false
      }
    },

    computed: {
      ...mapGetters({
        getCurrentBranch: 'settings/getCurrentBranch',
        getBranchList: 'settings/getBranchList'
      })
    },

    watch: {
      shopName(newVal) {
        this.entity = []
        const newBranch = this.getBranchList.find((el) => el.id === newVal[0].id)
        this.entities = newBranch?.entities ?? []
        if (this.entities?.length === 1) {
          this.entity.push(this.entities[0])
        }
      }
    },

    methods: {
      refetch() {
        this.$emit('refetch')
      },

      closeSidebar(hide = null) {
        if (hide !== null) {
          hide()
        }
        this.storage.pop()
        this.shopName.pop()
        this.entity.pop()
        this.nameCash = ''
        this.numberCash = ''
        this.catalogInPath = ''
        this.catalogOutPath = ''
        this.cashDeviceId = ''
      },

      showLabellModalGroupPrint() {
        this.isShowModalGroupPrint = true
      },
      closeLabellModalGroupPrint() {
        this.isShowModalGroupPrint = false
      },

      showLabellModalOutPath() {
        this.isShowModalOutPath = true
      },
      closeLabellModalOutPath() {
        this.isShowModalOutPath = false
      },

      showLabellModalInPath() {
        this.isShowModalInPath = true
      },
      closeLabellModalInPath() {
        this.isShowModalInPath = false
      },

      changeSwitchActive(newValue) {
        if (newValue) {
          this.isSwitchActive = newValue
          this.$bvModal.show('print-group-setting')
        } else {
          this.isSwitchActive = newValue
        }
      },

      async setFrontolDevice(hide) {
        if (
          this.entity.length === 0 ||
          this.storage.length === 0 ||
          this.shopName.length === 0 ||
          this.nameCash === '' ||
          this.numberCash === '' ||
          this.catalogInPath === '' ||
          this.catalogOutPath === ''
        ) {
          this.$noty.show(`Все поля должны быть заполнены`)
          return
        }
        const validateCatalogInPath = this.pathValidate(this.catalogInPath)
        const validateCatalogOutPath = this.pathValidate(this.catalogOutPath)

        if (validateCatalogInPath === true) {
          this.errorCatalogInPath = true
          return
        } else {
          this.errorCatalogInPath = false
          this.catalogInPath = validateCatalogInPath
        }

        if (validateCatalogOutPath === true) {
          this.errorCatalogOutPath = true
          return
        } else {
          this.errorCatalogOutPath = false
          this.catalogOutPath = validateCatalogOutPath
        }
        try {
          const { data } = await this.$apollo.mutate({
            mutation: require('../../gql/updateCash.graphql'),
            variables: {
              input: {
                id: this.cashDeviceId,
                name: this.nameCash,
                number: +this.numberCash,
                branch: this.shopName[0].id,
                storage: this.storage[0].id,
                entity: this.entity[0].id,
                in_path: this.catalogInPath,
                out_path: this.catalogOutPath
              }
            }
          })
          this.refetch()
          this.$noty.show(`Касса создана`)
          this.entity.pop()
          this.storage.pop()
          this.shopName.pop()
          this.nameCash = ''
          this.numberCash = ''
          this.cashDeviceId = ''
          this.catalogInPath = ''
          this.catalogOutPath = ''

          if (data?.UpdateCash.id) {
            this.dataOfTheCreatedCheckout = data?.UpdateCash
            this.$bvModal.show('frontol-boot-options')
          }
          hide()
        } catch (err) {
          console.error('error: ', err)
          this.$noty.show(`Что-то пошло не так. Попробуйте еще раз`)
        }
      },

      pathValidate(path) {
        const currentSymbol = {
          '/': 0,
          '\\': 0
        }
        const lastItemCatalog = path.charAt(path.length - 1)
        for (let i = 0; i < path.length; i++) {
          if (path[i] === '/') {
            currentSymbol['/'] += 1
          }
          if (path[i] === '\\') {
            currentSymbol['\\'] += 1
          }
        }
        if (currentSymbol['/'] > 0 && currentSymbol['\\'] > 0) return true
        if (currentSymbol['/'] === 0 && currentSymbol['\\'] === 0) return true

        if (currentSymbol[lastItemCatalog] !== undefined) {
          return path
        } else {
          if (currentSymbol['/'] > 0) {
            path = `${path + '/'}`
            return path
          }
          if (currentSymbol['\\'] > 0) {
            path = `${path + '\\'}`
            return path
          }
        }
      },
      async shownSidebar() {
        try {
          const { data } = await this.$apollo.mutate({
            mutation: require('../../gql/CreateFrontolCash.gql'),
            variables: {
              input: {
                branch: this.getCurrentBranch.id,
                integration: {
                  id: this.integration_id
                }
              }
            }
          })
          if (data) {
            this.refetch()
            this.numberCash = data?.CreateCash.number
            this.nameCash = `Касса № ${data?.CreateCash.number}`
            this.shopName.push(this.getCurrentBranch)
            this.cashDeviceId = data?.CreateCash.id
            if (this.storageList.length === 1) {
              this.storage.push(this.storageList[0])
            }
          }
        } catch (err) {
          console.log(err)
          this.$noty.show(`Что-то пошло не так. Попробуйте еще раз`)
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    #frontol-device-sidebar {
      width: 680px;
    }

    .b-sidebar-header {
      position: relative;
      background: #fff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      height: 85px;

      .header-left-block {
        &__button {
          color: var(--text-primary-gray-700, #313131);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          border-radius: 2px;
          background: var(--gray-gray-50, #efefef);
          margin-right: 16px;
        }
      }
    }

    .b-sidebar-footer {
      position: relative;
      background: #fff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      height: 65px;
      display: flex;
    }

    .b-sidebar-body {
      background: #fff;
      padding: 0;

      .sidebar-block {
        a {
          border-bottom: 1px solid #0066ff;
          font-size: 16px;
        }
      }

      .info {
        &__detail {
          a {
            color: #313131;
            font-weight: 600;
            border-bottom: 1px solid #313131;
          }
        }
      }
    }

    .form-block-frontol {
      .e-select-el .ms__chose {
        height: 42px;
      }

      &__input {
        input {
          height: 44px !important;
        }
      }

      &__title {
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }

      &__input-box-loading-catalog {
        width: 100%;
        display: flex;
        align-items: center;
        border-radius: 2px;
        border: 1px solid var(--text-placeholder-gray-300, #bcbcbc);
        margin-bottom: 22px;
      }

      &__input-loading-catalog {
        width: 100%;
        border: none;
        background: var(--gray-white, #fff);
        padding-left: 10px;
        padding-right: 12px;
        padding-top: 6px;
        padding-bottom: 6px;
        height: 42px !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
      }

      &__link-on-loading-catalog {
        margin-right: 12px;
      }

      &__block-chekbox {
        display: flex;
        align-items: center;
        margin-top: 22px;

        ::v-deep .custom-switch .custom-control-label::before {
          height: 22px;
          left: -2.25rem;
          width: 44px;
          pointer-events: all;
          border-radius: 24.5rem;
        }
        ::v-deep .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
          background-color: #fff;
          transform: translateX(1.5rem);
        }
        ::v-deep .custom-switch .custom-control-label::after {
          width: 16px;
          height: 16px;
        }
        ::v-deep .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
          transform: translateX(1.4rem) !important;
        }
        ::v-deep .custom-switch .custom-control-label::after {
          top: calc(0.25rem + 3px) !important;
          left: calc(-2.25rem + 3px);
        }
      }

      ::v-deep .custom-switch .custom-control-input:checked ~ .custom-control-label::before {
        border-color: #00cb91;
        background-color: #00cb91;
      }
    }
  }

  #frontol-device-sidebar {
    width: 680px;

    .b-sidebar-header {
      position: relative;
      background: #fff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

      .header-left-block {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 22px;
      }
    }

    .b-sidebar-footer {
      .b-action-buttons {
        padding: 0 22px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }

    .b-sidebar-body {
      .sidebar-block {
        border-bottom: 1px solid #e1e1e1;
        padding: 22px;

        p {
          font-size: 16px;
          line-height: 24px;
          color: #000000;
        }
      }

      .info {
        font-size: 16px;
        line-height: 24px;
        color: #000000;

        &__detail {
          background: #e6f0ff;
          border-radius: 4px;
          margin-top: 22px;
          border-left: 3px solid #b9d6ff;
          padding: 15px;

          img {
            margin-right: 15px;
          }
        }
      }
    }

    .frontol-content {
      margin-left: 22px;
      margin-right: 22px;
      margin-top: 32px;

      &__title {
        color: var(--text-primary-gray-700, #313131);
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .form-block-frontol {
      .e-select-el .ms__chose {
        height: 42px;
      }

      .e-select-el {
        &__option-name {
          margin: 6px 0px 6px 0px;
          text-align: left;
          color: var(--text-primary-gray-700, #313131);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
        }
        &__option-description-block {
          display: flex;
          margin-bottom: 6px;
        }
        &__option-description {
          margin: 0px 5px 0px 0px;
          text-align: left;
          color: var(--text-secondary-gray-400, #888);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
        }
      }

      &__lable-img-block {
        width: fit-content;
      }

      &__label {
        margin-bottom: 6px;
        color: var(--text-primary-gray-700, #313131);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-top: 22px;
        display: flex;
        align-items: center;
      }

      &__input {
        width: 100%;
        border-radius: 2px;
        background: var(--gray-white, #fff);
        padding-top: 6px;
        padding-bottom: 6px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
      }
      &__input::-webkit-input-placeholder {
        color: rgb(188, 188, 188);
      }
      &__input:-moz-placeholder {
        color: red;
      }
      &__input::-moz-placeholder {
        color: red;
      }
      &__input:-ms-input-placeholder {
        color: red;
      }
    }

    .text-label-input-modal {
      color: var(--gray-white, #fff);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      margin: 0;
    }
  }
</style>
