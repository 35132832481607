<template>
  <b-modal
    id="print-group-setting"
    centered
    hide-footer
    size="lg"
    @hidden="changePrintGroupSetting"
  >
    <template #modal-header>
      <div class="header-container">
        <div class="title-block d-flex justify-content-between w-100">
          <h5 class="title">Настройка правил групп печати</h5>
          <b-button class="close">
            <img
              src="/img/icons/modal_close.svg"
              alt=""
              @click="hideModal"
            />
          </b-button>
        </div>

        <div
          v-if="showInfo"
          class="info-container"
        >
          <div class="d-flex align-items-center">
            <img
              src="/img/icons/info-blue.svg"
              alt="info"
            />
            <p>
              Настройте группы печати на кассе: <a class="info-container__link">см. руководство.</a> полученные коды и
              наименования групп укажите здесь
            </p>
          </div>
          <!-- <img
                  class="close-help"
                  src="/img/icons/blue-close.svg"
                  alt="close"
                  @click="closeInfo"
                /> -->
        </div>
      </div>
    </template>

    <div class="modal-body">
      <div class="modal-content">
        <form class="modal-content__form">
          <div
            v-for="(data, index) in printGroupData"
            :key="data.id"
            class="modal-content__form-block"
          >
            <div class="modal-content__form-box">
              <label class="modal-content__label">Юр. лицо</label>
              <div class="modal-content__form-box-block-select">
                <e-select
                  v-model="data.legalEntity"
                  class="e-select-el"
                  :options="entities"
                  serchable
                  :max_selected_count="1"
                  placeholder="Выберите Юр. лицо"
                  select_name="entity_select"
                >
                  <template #custom_name="{ item }">
                    <div>
                      <p class="e-select-el__option-name">{{ item.name }}</p>
                    </div>
                  </template>
                </e-select>
              </div>
            </div>

            <div class="modal-content__form-box">
              <label class="modal-content__label">Наименование</label>
              <input
                v-model="data.nameGroup"
                class="modal-content__input"
                type="text"
              />
            </div>

            <div class="modal-content__form-box">
              <label class="modal-content__label">Код</label>
              <div class="modal-content__form-box-block-select">
                <input
                  v-model="data.printGroupCode"
                  class="modal-content__input"
                  type="number"
                />
                <img
                  v-if="index > 0"
                  class="cursor"
                  style="margin-left: 16px"
                  src="/img/integrations/frontol/bin.svg"
                  alt=""
                  @click="deletePrintGroup(data.id)"
                />
              </div>
            </div>
          </div>

          <div class="modal-content__form-block-button">
            <button
              class="modal-content__form-button"
              @click.prevent="addPrintGroup"
            >
              <img
                class="cursor"
                style="margin-top: 23px"
                src="/img/integrations/frontol/Plus.svg"
                alt=""
              />
              <p>Добавить</p>
            </button>
          </div>
        </form>
      </div>

      <template>
        <div class="modal-body__footer">
          <div class="b-action-buttons">
            <b-button
              variant="primary"
              class="cursor"
              @click="
                {
                }
              "
            >
              Сохранить
            </b-button>
            <b-button
              variant="outline-primary"
              class="cursor ml-3"
              @click="hideModal"
            >
              Отмена
            </b-button>
          </div>
        </div>
      </template>
    </div>
  </b-modal>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'PrintGroupSetting',

    apollo: {
      Branch: {
        query: require('../gql/GetBranchEntities.gql'),
        variables() {
          return {
            id: this.getCurrentBranch.id
          }
        },
        result({ data }) {
          this.entities = data?.Branch.entities ?? []
        },
        error(err) {
          console.log(err)
        }
      }
    },

    data() {
      return {
        showInfo: true,
        entities: [],
        printGroupData: [
          { id: 1, nameGroup: '', legalEntity: [], printGroupCode: '' },
          { id: 2, nameGroup: '', legalEntity: [], printGroupCode: '' }
        ]
      }
    },

    computed: {
      ...mapGetters({
        getCurrentBranch: 'settings/getCurrentBranch'
      })
    },

    methods: {
      hideModal() {
        this.$bvModal.hide('print-group-setting')
      },

      addPrintGroup() {
        const newPrintGroup = { id: Date.now(), nameGroup: '', legalEntity: [], printGroupCode: '' }
        this.printGroupData.push(newPrintGroup)
      },

      changePrintGroupSetting() {
        this.printGroupData.forEach((el) => {
          if (el.nameGroup === '' || el.legalEntity.length === 0 || el.printGroupCode === '') {
            this.$emit('close_switch')
            return
          }
        })
      },

      deletePrintGroup(id) {
        this.printGroupData = this.printGroupData.filter((el) => el.id !== id)
      }
    }
  }
</script>

<style lang="scss" scoped>
  #print-group-setting {
    .header-container {
      width: 100%;
      .title-block {
        margin-bottom: 16px;
        .title {
          margin: 0;
          font-family: 'Gilroy-Medium', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          color: #313131;
        }
      }
      .info-container {
        margin-bottom: 22px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px;
        width: 100%;
        background: #eef9ff;
        border: 1px solid #99daff;
        border-radius: 2px;
        .close-help {
          cursor: pointer;
        }
        p {
          margin: 0 0 0 13px;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #313131;
        }

        &__link {
          color: var(--text-system-blue-400, #00a3ff);
          text-decoration-line: underline;
        }
      }
    }

    .modal-body {
      &__footer {
        margin-top: 30px;
        margin-bottom: 22px;
      }
    }
    .modal-content {
      padding: 0;
      &__form-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
      }

      &__form-box {
        width: 33%;
        margin-right: 16px;
      }

      &__label {
        color: var(--text-primary-gray-700, #313131);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }

      &__form-block-button {
        margin-top: 22px;
      }

      &__form-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: none;
        width: 83px;
        background-color: white;

        p {
          margin: 0;
          color: var(--text-system-blue-400, #00a3ff);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
        img {
          margin: 0 !important;
        }
      }

      &__form-box-block-select {
        width: 100%;
        display: flex;
        align-items: center;
      }

      &__input {
        width: 100%;
        border-radius: 2px;
        border: 1px solid var(--text-placeholder-gray-300, #bcbcbc);
        background: var(--gray-white, #fff);
        padding-left: 10px;
        padding-right: 12px;
        padding-top: 6px;
        padding-bottom: 6px;
        height: 34px !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
      }
      &__input::-webkit-input-placeholder {
        color: rgb(188, 188, 188);
      }
      &__input:-moz-placeholder {
        color: red;
      }
      &__input::-moz-placeholder {
        color: red;
      }
      &__input:-ms-input-placeholder {
        color: red;
      }

      .e-select-el {
        &__option-name {
          margin: 6px 0px 6px 0px;
          text-align: left;
          color: var(--text-primary-gray-700, #313131);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
        }
        &__option-description-block {
          display: flex;
          margin-bottom: 6px;
        }
        &__option-description {
          margin: 0px 5px 0px 0px;
          text-align: left;
          color: var(--text-secondary-gray-400, #888);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
        }
      }
    }
  }
</style>
