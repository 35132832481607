var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "frontol-boot-options",
      "centered": "",
      "hide-footer": "",
      "size": "lg",
      "no-close-on-backdrop": true
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function () {
        return [_c('div', {
          staticClass: "header-container"
        }, [_c('div', {
          staticClass: "title-block d-flex justify-content-between w-100"
        }, [_c('div', {
          staticClass: "header-block-title"
        }, [_c('h5', {
          staticClass: "title"
        }, [_vm._v("Загрузка кассы")]), _c('div', {
          staticClass: "header-descriptoin"
        }, [_c('div', {
          staticClass: "header-descriptoin__delimiter"
        }), _c('div', [_vm._v("Касса №" + _vm._s(_vm.cash_device.number))]), _c('div', {
          staticClass: "header-descriptoin__delimiter"
        }), _c('div', {
          class: {
            'active-device': _vm.cash_device.active,
            'no-active-device': !_vm.cash_device.active
          }
        }, [_vm._v(" " + _vm._s(_vm.cash_device.active ? 'Активна' : 'Не активеа') + " ")])])]), _c('b-button', {
          staticClass: "close"
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/modal_close.svg",
            "alt": ""
          },
          on: {
            "click": _vm.hideModal
          }
        })])], 1), _vm.showInfo ? _c('div', {
          staticClass: "info-container"
        }, [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/info-blue.svg",
            "alt": "info"
          }
        }), _c('p', [_vm._v("Требуется загрузить параметры системы для работы с новой кассой")])])]) : _vm._e()])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "modal-content"
  }, [_c('form', {
    staticClass: "modal-content__form"
  }, [_c('div', {
    staticClass: "modal-content__form-checkbox-box"
  }, [_c('b-form-checkbox', {
    model: {
      value: _vm.isDownloadStaff,
      callback: function ($$v) {
        _vm.isDownloadStaff = $$v;
      },
      expression: "isDownloadStaff"
    }
  }), _c('label', {
    staticClass: "modal-content__form-checkbox-box-label"
  }, [_vm._v("Персонал")])], 1), _vm.isDownloadStaff ? [_vm._l(_vm.listOfEmployeesToLoading, function (data, index) {
    return _c('div', {
      key: index,
      staticClass: "modal-content__form-block"
    }, [_c('div', {
      staticClass: "modal-content__form-box"
    }, [_c('label', {
      staticClass: "modal-content__label"
    }, [_vm._v("Сотрудник")]), _c('div', {
      staticClass: "modal-content__form-box-block-select"
    }, [_c('e-select', {
      staticClass: "e-select-el",
      attrs: {
        "options": _vm.getUsers,
        "serchable": "",
        "max_selected_count": 1,
        "placeholder": "Выберите сотрудника",
        "select_name": "users_select"
      },
      scopedSlots: _vm._u([{
        key: "custom_name",
        fn: function (_ref) {
          var _item$last_name;

          var item = _ref.item;
          return [_c('div', [_c('p', {
            staticClass: "e-select-el__option-name"
          }, [_vm._v(_vm._s(`${(_item$last_name = item.last_name) !== null && _item$last_name !== void 0 ? _item$last_name : ''} ${item.first_name}`))]), _c('div', {
            staticClass: "e-select-el__option-description-block"
          }, [item.roles !== null ? _c('p', {
            staticClass: "e-select-el__option-description"
          }, [_vm._v(" " + _vm._s(item.roles === null ? '' : item.roles[0].name) + " ")]) : _vm._e(), item.roles !== null ? _c('div', {
            staticClass: "e-select-el__option-delimiter"
          }) : _vm._e(), _c('p', {
            staticClass: "e-select-el__option-description"
          }, [_vm._v(_vm._s(_vm.cash_device.branch.name))]), _c('p', {
            staticClass: "e-select-el__option-description"
          }, [_vm._v("в городе")]), _c('p', {
            staticClass: "e-select-el__option-description"
          }, [_vm._v(_vm._s(_vm.cash_device.branch.address.city) + ",")]), _c('p', {
            staticClass: "e-select-el__option-description"
          }, [_vm._v(_vm._s(_vm.cash_device.branch.address.street))]), _c('p', {
            staticClass: "e-select-el__option-description"
          }, [_vm._v(_vm._s(_vm.cash_device.branch.address.apartment))])])])];
        }
      }], null, true),
      model: {
        value: data.employee,
        callback: function ($$v) {
          _vm.$set(data, "employee", $$v);
        },
        expression: "data.employee"
      }
    })], 1)]), _c('div', {
      staticClass: "modal-content__form-box"
    }, [_c('div', {
      staticClass: "modal-content__label-password-block"
    }, [_c('label', {
      staticClass: "modal-content__label"
    }, [_vm._v("Пароль от кассы")]), _c('p', {
      staticClass: "cursor modal-content__generate-pass-button",
      on: {
        "click": function ($event) {
          return _vm.getGeneratePass(index);
        }
      }
    }, [_vm._v(" Сгенерировать ")])]), _c('div', {
      staticClass: "modal-content__form-box-block-select"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: data.checkoutPassword,
        expression: "data.checkoutPassword"
      }],
      staticClass: "modal-content__input",
      attrs: {
        "type": "number"
      },
      domProps: {
        "value": data.checkoutPassword
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) return;

          _vm.$set(data, "checkoutPassword", $event.target.value);
        }
      }
    })])]), index > 0 ? _c('img', {
      staticClass: "cursor",
      staticStyle: {
        "margin-top": "20px"
      },
      attrs: {
        "src": "/img/integrations/frontol/bin.svg",
        "alt": ""
      },
      on: {
        "click": function ($event) {
          return _vm.deleteEmployeeToLoadTheCashRegister(data.id);
        }
      }
    }) : _vm._e()]);
  }), _c('div', {
    staticClass: "modal-content__form-block-button"
  }, [_c('button', {
    staticClass: "modal-content__form-button",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.addEmployeeToLoadTheCashRegister.apply(null, arguments);
      }
    }
  }, [_c('img', {
    staticClass: "cursor",
    staticStyle: {
      "margin-top": "23px"
    },
    attrs: {
      "src": "/img/integrations/frontol/Plus.svg",
      "alt": ""
    }
  }), _c('p', {
    staticClass: "modal-content__form-button-text"
  }, [_vm._v("Добавить")])])])] : [_c('p', {
    staticClass: "modal-content__info-download-staff"
  }, [_vm._v(" Персонал на кассу не будет загружен, вам потребуется завести его самостоятельно через кассу ")])], _c('div', {
    staticClass: "modal-content__form-checkbox-box"
  }, [_c('b-form-checkbox', {
    attrs: {
      "disabled": true
    },
    model: {
      value: _vm.isDiscountsAndRestrictions,
      callback: function ($$v) {
        _vm.isDiscountsAndRestrictions = $$v;
      },
      expression: "isDiscountsAndRestrictions"
    }
  }), _c('label', {
    staticClass: "modal-content__form-checkbox-box-label-in-developing"
  }, [_vm._v("Скидки и ограничения")]), _c('div', {
    staticClass: "in-developing"
  }, [_vm._v("в разработке")])], 1), _c('div', {
    staticClass: "modal-content__form-checkbox-box"
  }, [_c('b-form-checkbox', {
    attrs: {
      "disabled": true
    },
    model: {
      value: _vm.isDiscountCards,
      callback: function ($$v) {
        _vm.isDiscountCards = $$v;
      },
      expression: "isDiscountCards"
    }
  }), _c('label', {
    staticClass: "modal-content__form-checkbox-box-label-in-developing"
  }, [_vm._v("Дисконтные карты")]), _c('div', {
    staticClass: "in-developing"
  }, [_vm._v("в разработке")])], 1)], 2)]), [_c('div', {
    staticClass: "modal-body__footer"
  }, [_c('div', {
    staticClass: "b-action-buttons"
  }, [_c('b-button', {
    staticClass: "cursor",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.saveTheLoadingCashDevice
    }
  }, [_vm._v(" Загрузить ")]), _c('b-button', {
    staticClass: "cursor ml-3",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.hideModal
    }
  }, [_vm._v(" Отмена ")])], 1)])]], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }