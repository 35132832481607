<template>
  <div>
    <download-cash-docs @download="cashDownload" />
    <cash-load-modal :current_item="currentItemToLoad" />
    <div class="d-flex">
      <i-select
        v-model="cash_filter"
        placeholder="Все кассы"
        style="width: 116px; margin-right: 15px"
      >
        <i-option value="all">Все кассы</i-option>
        <i-option value="evotor">Эвотор</i-option>
        <i-option value="dklink">DKLink</i-option>
      </i-select>
      <i-select
        v-model="status_filter"
        placeholder="Все статусы"
        style="width: 116px"
      >
        <i-option value="all"> Все статусы</i-option>
        <i-option value="active"> Активные</i-option>
        <i-option value="disabled"> Выключенные</i-option>
      </i-select>
      <b-overlay
        class="ml-auto d-inline-block"
        :show="isLoading"
        rounded="sm"
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
      >
        <!--        <b-button-->
        <!--          variant="primary"-->
        <!--          @click="syncCash"-->
        <!--        >-->
        <!--          Обновить список касс-->
        <!--        </b-button>-->
        <b-dropdown
          no-caret
          variant="primary"
        >
          <template #button-content>
            <div>
              <img
                src="/img/icons/btn-plus.svg"
                alt=""
                class="mr"
              />
              Добавить кассу
            </div>
          </template>
          <!--          <b-dropdown-item @click="clearOrder">-->
          <!--            Обновить заказ-->
          <!--          </b-dropdown-item>-->
          <b-dropdown-item
            v-if="cash_tags.dklink"
            @click="createCash"
          >
            DKLink FO
          </b-dropdown-item>

          <b-dropdown-item v-if="cash_tags.frontol">
            <a
              v-b-toggle.frontol-device-sidebar
              class="dropdown-frontol"
              >Frontol</a
            >
          </b-dropdown-item>

          <b-dropdown-item @click="syncCash">Обновить список касс Эвотор</b-dropdown-item>
        </b-dropdown>
      </b-overlay>
    </div>
    <b-card
      style="margin-top: 16px"
      no-body
      class="p-0"
    >
      <devices-table-filter
        style="margin: 16px; width: 270px"
        @handler_pagination="search"
      />
      <b-table
        responsive
        class="b-table__wrapper"
        :fields="fields"
        :items="table_items"
        @row-clicked="openDevice"
      >
        <template #head(id)>
          <b-form-checkbox />
        </template>
        <template #cell(id)="data">
          <b-form-checkbox
            v-model="selected"
            :value="data.value"
          />
        </template>
        <template #cell(branch)="data">
          {{ data.value?.name }}
        </template>
        <template #cell(type)="data">
          {{ getTypeName(data.item?.integration?.type) }}
        </template>
        <template #cell(storage)="data">
          {{ data.value?.name }}
        </template>
        <template #cell(status)="data">
          <b-badge :variant="`${data.item.status ? 'success' : 'danger'}`">
            {{ data.item.status ? 'Активна' : 'Выключена' }}
          </b-badge>
        </template>
      </b-table>
      <table-navbar
        :items="selected"
        hide_print
        hide_remove
        items_name="cash"
        @remove_items="remove_items"
        @cash_load="cashLoad"
        @cashDownload="cashDownloadModal"
      />
    </b-card>
    <frontol-sidebar-device
      :integration_id="cash_tags.frontol"
      @refetch="$apollo.queries.CashItems.refetch()"
    />
  </div>
</template>

<script>
  import DevicesTableFilter from '@/views/settings/components/devices/DevicesTableFilter'
  import { mapGetters } from 'vuex'
  import TableNavbar from '@/views/shared/components/table/TableNavbar'
  import { integration_map } from '@/utils/constants'
  import DownloadCashDocs from '@/views/settings/components/devices/DownloadCashDocs.vue'
  import FrontolSidebarDevice from '@/views/settings/components/devices/FrontolSidebarDevice.vue'
  import CashLoadModal from '@/views/settings/modal/CashLoadModal.vue'

  export default {
    name: 'CashDevices',
    components: { DownloadCashDocs, DevicesTableFilter, TableNavbar, FrontolSidebarDevice, CashLoadModal },
    apollo: {
      CashItems: {
        query: require('../../gql/CashItems.graphql'),
        variables() {
          return {
            branch: this.currentBranch.id
          }
        },
        result({ data }) {
          this.items = data?.CashItems ?? []
        }
      },
      AllIntegrations: {
        query: require('../../../integrations/gql/getIntegrations.graphql'),
        result({ data }) {
          this.all_integrations = data.AllIntegrations
          this.all_integrations?.cash_integrations?.forEach((el) => (this.cash_tags[el.type] = el.id))
        }
      }
    },
    data() {
      return {
        isLoading: false,
        all_integrations: {},
        status_filter: 'all',
        cash_filter: 'all',
        search_str: '',
        fields: [
          { key: 'id', label: '', thStyle: 'width: 50px' },
          { key: 'type', label: 'Тип' },
          { key: 'number', label: '№ Кассы' },
          { key: 'name', label: 'Название' },
          { key: 'branch', label: 'Магазин' },
          { key: 'storage', label: 'Место хранения' },
          // { key: 'active', label: 'Статус' },
          { key: 'status', label: 'Состояние' }
        ],
        items: [],
        currentItemToLoad: {},
        selected: [],
        cash_tags: {
          frontol: null,
          dklink: null
        }
      }
    },

    methods: {
      async syncCash() {
        this.isLoading = true
        try {
          for (const integration of this.all_integrations.cash_integrations || [])
            if (integration?.id && integration.type === 'evotor')
              await this.$apollo.mutate({
                mutation: require('../../gql/SyncCash.graphql'),
                variables: {
                  input: {
                    id: integration?.id,
                    branch: this.currentBranch.id
                  }
                }
              })
        } catch (e) {
          console.error(e)
        } finally {
          this.isLoading = false
        }
      },
      cashDownloadModal() {
        this.$bvModal.show('download-cash-docs')
      },
      async cashDownload(period) {
        try {
          await this.$apollo.mutate({
            mutation: require('../../gql/CreateCashTask.graphql'),
            variables: {
              input: {
                branch: this.currentBranch.id,
                period: {
                  begin: period[0],
                  end: period[1]
                }
              }
            }
          })
          this.$noty.show(`Успешно`)
        } catch (e) {
          console.error(e)
          this.$noty.show(`Что-то пошло не так. Попробуйте еще раз`)
        } finally {
          this.selected = []
        }
      },

      search(data) {
        this.search_str = data
      },
      openDevice(item) {
        this.$router.push({ name: 'devices.item', params: { id: item.id } })
      },
      remove_items() {
        this.selected = []
      },
      getTypeName(value) {
        return integration_map[value]
      },
      cashLoad(id) {
        this.currentItemToLoad = this.table_items.find(el => el.id === id[0])
        this.$bvModal.show('cash-load-modal')
      },
      async exportData() {
        try {
          this.isIntegrating = true
          await this.$apollo.mutate({
            mutation: require('../../../integrations/gql/UploadClassif.gql'),
            variables: {
              integration: this.items.find((el) => el.id === this.selected[0])?.integration?.id,
              branch: this.currentBranch.id
            }
          })
          this.$noty.show(`Экспорт данных успешен`)
        } catch (e) {
          console.error(e)
          this.$noty.show(`При экспорте что-то пошло не так. Попробуйте еще раз`)
        } finally {
          this.isIntegrating = false
          this.selected = []
        }
      },
      async createCash(id) {
        const { data } = await this.$apollo.mutate({
          mutation: require('../../gql/CreateCash.graphql'),
          variables: {
            input: {
              branch: this.currentBranch.id,
              integration: {
                id: id
              }
            }
          }
        })
        await this.$router.push({ name: 'devices.item', params: { id: data.CreateCash.id } })
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      }),
      table_items() {
        return this.items?.filter((el) => {
          let flag = false
          if (this.cash_filter === 'all') flag = true
          if (this.cash_filter !== 'all' && el?.integration?.type === this.cash_filter) flag = true

          if (this.status_filter === 'active') flag = flag && el.active === true
          if (this.status_filter === 'disabled') flag = flag && el.active === false

          flag = flag && (el.name.includes(this.search_str) || ('' + el.number).includes(this.search_str))

          return flag
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .badge-danger {
      background: #ffebee !important;
      color: #e53835 !important;
    }

    .modal-footer button {
      margin: 0px;
    }

    .info-modal {
      background: #eef9ff;
      margin-left: 22px;
      padding: 22px;
      color: #313131;
      margin-right: 22px;
    }

    .alert-header {
      margin-left: 0px;
    }

    .alert-footer {
      margin-left: 0px;
    }

    .spinner-border {
      margin-top: 0px;
    }
  }

  .dropdown-frontol {
    padding-left: 0 !important;
    padding-right: 80% !important;
  }
</style>
