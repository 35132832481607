<template>
  <div id="devices-block">
    <!-- <div class="content__header">Оборудование</div> -->
    <b-tabs
      v-model="activeTab"
      pills
      card
      nav-wrapper-class="tags-header"
      active-nav-item-class="tag-item-active"
    >
      <b-tab
        title-link-class="tag-item"
        title="Кассы"
      >
        <cash-devices />
      </b-tab>
      <b-tab
        title-link-class="tag-item"
        title="Списки загрузки весов"
      >
        <scales-devices v-if="activeTab === 1" />
      </b-tab>
      <b-tab
        title-link-class="tag-item"
        title="Принтеры"
      >
        <printer-devices v-if="activeTab === 2" />
      </b-tab>
      <b-tab
        title-link-class="tag-item"
        title="УТМ"
      >
        <utm-device v-if="activeTab === 3" />
      </b-tab>
      <!--      <b-tab title-link-class="tag-item" title="Принтеры">Принтеры</b-tab>-->
      <!--      <b-tab title-link-class="tag-item" title="Ценники">Ценники</b-tab>-->
      <!--      <b-tab title-link-class="tag-item" title="Сканеры">Сканеры</b-tab>-->
    </b-tabs>
  </div>
</template>

<script>
  import CashDevices from '@/views/settings/components/devices/CashDevices.vue'
  import ScalesDevices from '@/views/settings/components/devices/ScalesDevices.vue'
  import PrinterDevices from '@/views/settings/components/devices/PrinterDevices.vue'
  import UtmDevice from '@/views/settings/pages/UtmDevice.vue'
  export default {
    name: 'Devices',
    components: { UtmDevice, CashDevices, ScalesDevices, PrinterDevices },
    data() {
      return {
        tabIndex: 0
      }
    },
    computed: {
      activeTab: {
        set(val) {
          let query = { ...this.$route.query }
          query.tab = val
          this.$router.replace({ query: query }).catch((er) => er)
        },
        get() {
          return this.$route.query.tab ? +this.$route.query.tab : 0
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    // #devices-block {
    //   display: flex;
    //   flex-direction: column;
    //   flex: 1;
    // }

    .tags-header {
      background-color: transparent;
      border: none;
      padding-left: 0.625rem;
      margin-top: 0;
      padding: 0 0px 0 12px !important;
    }

    .tag-item {
      background: #ffffff !important;
      border: 1px solid #e1e1e1 !important;
      border-radius: 100px !important;
      margin-right: 10px;
    }

    .tag-item-active {
      background: #00a3ff !important;
      margin-right: 16px;
    }
    .tabs {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .tab-content {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
    .tab-pane.active {
      flex: 1;
      display: flex !important;
      flex-direction: column !important;
    }
  }

  #devices-block {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
</style>
