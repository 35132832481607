var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "print-group-setting",
      "centered": "",
      "hide-footer": "",
      "size": "lg"
    },
    on: {
      "hidden": _vm.changePrintGroupSetting
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function () {
        return [_c('div', {
          staticClass: "header-container"
        }, [_c('div', {
          staticClass: "title-block d-flex justify-content-between w-100"
        }, [_c('h5', {
          staticClass: "title"
        }, [_vm._v("Настройка правил групп печати")]), _c('b-button', {
          staticClass: "close"
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/modal_close.svg",
            "alt": ""
          },
          on: {
            "click": _vm.hideModal
          }
        })])], 1), _vm.showInfo ? _c('div', {
          staticClass: "info-container"
        }, [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/info-blue.svg",
            "alt": "info"
          }
        }), _c('p', [_vm._v(" Настройте группы печати на кассе: "), _c('a', {
          staticClass: "info-container__link"
        }, [_vm._v("см. руководство.")]), _vm._v(" полученные коды и наименования групп укажите здесь ")])])]) : _vm._e()])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "modal-content"
  }, [_c('form', {
    staticClass: "modal-content__form"
  }, [_vm._l(_vm.printGroupData, function (data, index) {
    return _c('div', {
      key: data.id,
      staticClass: "modal-content__form-block"
    }, [_c('div', {
      staticClass: "modal-content__form-box"
    }, [_c('label', {
      staticClass: "modal-content__label"
    }, [_vm._v("Юр. лицо")]), _c('div', {
      staticClass: "modal-content__form-box-block-select"
    }, [_c('e-select', {
      staticClass: "e-select-el",
      attrs: {
        "options": _vm.entities,
        "serchable": "",
        "max_selected_count": 1,
        "placeholder": "Выберите Юр. лицо",
        "select_name": "entity_select"
      },
      scopedSlots: _vm._u([{
        key: "custom_name",
        fn: function (_ref) {
          var item = _ref.item;
          return [_c('div', [_c('p', {
            staticClass: "e-select-el__option-name"
          }, [_vm._v(_vm._s(item.name))])])];
        }
      }], null, true),
      model: {
        value: data.legalEntity,
        callback: function ($$v) {
          _vm.$set(data, "legalEntity", $$v);
        },
        expression: "data.legalEntity"
      }
    })], 1)]), _c('div', {
      staticClass: "modal-content__form-box"
    }, [_c('label', {
      staticClass: "modal-content__label"
    }, [_vm._v("Наименование")]), _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: data.nameGroup,
        expression: "data.nameGroup"
      }],
      staticClass: "modal-content__input",
      attrs: {
        "type": "text"
      },
      domProps: {
        "value": data.nameGroup
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) return;

          _vm.$set(data, "nameGroup", $event.target.value);
        }
      }
    })]), _c('div', {
      staticClass: "modal-content__form-box"
    }, [_c('label', {
      staticClass: "modal-content__label"
    }, [_vm._v("Код")]), _c('div', {
      staticClass: "modal-content__form-box-block-select"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: data.printGroupCode,
        expression: "data.printGroupCode"
      }],
      staticClass: "modal-content__input",
      attrs: {
        "type": "number"
      },
      domProps: {
        "value": data.printGroupCode
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) return;

          _vm.$set(data, "printGroupCode", $event.target.value);
        }
      }
    }), index > 0 ? _c('img', {
      staticClass: "cursor",
      staticStyle: {
        "margin-left": "16px"
      },
      attrs: {
        "src": "/img/integrations/frontol/bin.svg",
        "alt": ""
      },
      on: {
        "click": function ($event) {
          return _vm.deletePrintGroup(data.id);
        }
      }
    }) : _vm._e()])])]);
  }), _c('div', {
    staticClass: "modal-content__form-block-button"
  }, [_c('button', {
    staticClass: "modal-content__form-button",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.addPrintGroup.apply(null, arguments);
      }
    }
  }, [_c('img', {
    staticClass: "cursor",
    staticStyle: {
      "margin-top": "23px"
    },
    attrs: {
      "src": "/img/integrations/frontol/Plus.svg",
      "alt": ""
    }
  }), _c('p', [_vm._v("Добавить")])])])], 2)]), [_c('div', {
    staticClass: "modal-body__footer"
  }, [_c('div', {
    staticClass: "b-action-buttons"
  }, [_c('b-button', {
    staticClass: "cursor",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        {}
      }
    }
  }, [_vm._v(" Сохранить ")]), _c('b-button', {
    staticClass: "cursor ml-3",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.hideModal
    }
  }, [_vm._v(" Отмена ")])], 1)])]], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }