var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "cash-load-modal",
      "centered": "",
      "hide-footer": "",
      "size": "md"
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function () {
        var _vm$current_item$numb;

        return [_c('div', {
          staticClass: "header-container"
        }, [_c('div', {
          staticClass: "title-block d-flex justify-content-between w-100"
        }, [_c('div', {
          staticClass: "header-block-title"
        }, [_c('h5', {
          staticClass: "title"
        }, [_vm._v("Загрузка кассы")]), _c('div', {
          staticClass: "header-descriptoin"
        }, [_c('div', {
          staticClass: "header-descriptoin__delimiter"
        }), _c('div', [_vm._v("Касса № " + _vm._s((_vm$current_item$numb = _vm.current_item.number) !== null && _vm$current_item$numb !== void 0 ? _vm$current_item$numb : ''))]), _c('div', {
          staticClass: "header-descriptoin__delimiter"
        }), _c('div', {
          class: {
            'active-device': _vm.current_item.active,
            'no-active-device': !_vm.current_item.active
          }
        }, [_vm._v(" " + _vm._s(_vm.current_item.active ? 'Активна' : 'Не активеа') + " ")])])]), _c('b-button', {
          staticClass: "close"
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/modal_close.svg",
            "alt": ""
          },
          on: {
            "click": _vm.hideModal
          }
        })])], 1), _vm.showInfo ? _c('div', {
          staticClass: "info-container"
        }, [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/info-blue.svg",
            "alt": "info"
          }
        }), _c('p', [_vm._v("Система обновит все данные на кассе. Мы сообщим, когда процесс завершится")])])]) : _vm._e()])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "modal-content"
  }, [_c('form', {
    staticClass: "modal-content__form"
  }, [_c('div', {
    staticClass: "modal-content__form-checkbox-box"
  }, [_c('b-form-checkbox', {
    model: {
      value: _vm.isDownloadStaff,
      callback: function ($$v) {
        _vm.isDownloadStaff = $$v;
      },
      expression: "isDownloadStaff"
    }
  }), _c('label', {
    staticClass: "modal-content__form-checkbox-box-label"
  }, [_vm._v("Загрузить персонал")])], 1), _c('div', {
    staticClass: "modal-content__form-checkbox-box"
  }, [_c('b-form-checkbox', {
    attrs: {
      "disabled": true
    },
    model: {
      value: _vm.isDiscountsAndRestrictions,
      callback: function ($$v) {
        _vm.isDiscountsAndRestrictions = $$v;
      },
      expression: "isDiscountsAndRestrictions"
    }
  }), _c('label', {
    staticClass: "modal-content__form-checkbox-box-label-in-developing"
  }, [_vm._v("Скидки и ограничения")]), _c('div', {
    staticClass: "in-developing"
  }, [_vm._v("в разработке")])], 1), _c('div', {
    staticClass: "modal-content__form-checkbox-box"
  }, [_c('b-form-checkbox', {
    attrs: {
      "disabled": true
    },
    model: {
      value: _vm.isDiscountCards,
      callback: function ($$v) {
        _vm.isDiscountCards = $$v;
      },
      expression: "isDiscountCards"
    }
  }), _c('label', {
    staticClass: "modal-content__form-checkbox-box-label-in-developing"
  }, [_vm._v("Дисконтные карты")]), _c('div', {
    staticClass: "in-developing"
  }, [_vm._v("в разработке")])], 1)])]), [_c('div', {
    staticClass: "modal-body__footer"
  }, [_c('div', {
    staticClass: "b-action-buttons"
  }, [_c('b-button', {
    staticClass: "cursor",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.loadCashRegisters
    }
  }, [_vm._v(" Загрузить ")]), _c('b-button', {
    staticClass: "cursor ml-3",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.hideModal
    }
  }, [_vm._v(" Отмена ")])], 1)])]], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }