var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-filter"
  }, [_c('b-form', {
    staticClass: "pos-r",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.handlerPagination.apply(null, arguments);
      }
    }
  }, [_c('b-form-input', {
    staticClass: "filter-search",
    attrs: {
      "type": "text",
      "placeholder": "Поиск"
    },
    on: {
      "input": _vm.handlerPagination
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _vm.search.length > 0 ? _c('b-button', {
    staticClass: "btn-clear-search-str",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.clear.apply(null, arguments);
      }
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }