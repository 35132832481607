<template>
  <div>
    <b-modal
      id="download-cash-docs"
      centered
      hide-footer
    >
      <template #modal-header>
        <h5>Выгрузка продаж с кассы</h5>
      </template>

      <div class="info-message mb-3">
        Система перезапишет кассовые документы за выбранный вами период при загрузке данных
      </div>
      Период выгрузки
      <work-period
        class="mb-3"
        type_doc="cash"
        @date_range="setDateRange"
      />
      <div class="d-flex mb-4">
        <e-button
          variant="primary"
          class="mr-3"
          @click="download"
        >
          Выгрузить
        </e-button>
        <e-button
          variant="outline-primary"
          @click="cancel"
        >
          Отмена
        </e-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import WorkPeriod from '@/components/WorkPeriod.vue'

  export default {
    name: 'DownloadCashDocs',
    components: { WorkPeriod },
    date() {
      return {
        period: null
      }
    },
    methods: {
      setDateRange(date) {
        this.period = date
      },
      download() {
        this.$emit('download', this.period)
        this.$bvModal.hide('download-cash-docs')
      },
      cancel() {
        this.$bvModal.hide('download-cash-docs')
      }
    }
  }
</script>
<style scoped lang="scss">
  .info-message {
    border-radius: 4px;
    background: #eef9ff;
    border-left: 3px #99daff solid;
    padding: 16px;
  }
</style>
