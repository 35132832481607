import { render, staticRenderFns } from "./ListSvg.vue?vue&type=template&id=0f2d7807&scoped=true&"
import script from "./ListSvg.vue?vue&type=script&lang=js&"
export * from "./ListSvg.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f2d7807",
  null
  
)

export default component.exports