<template>
  <section id="printer-inner">
    <b-sidebar
      id="printer-sidebar"
      title="Bixolon"
      right
      backdrop
      shadow
    >
      <template #header="{ hide }">
        Bixolon
        <img
          class="close-sidebar cursor"
          src="/img/integrations/icon-sidebar-close.svg"
          alt=""
          @click="hide"
        />
      </template>

      <template>
        <div class="sidebar-block info">
          <p class="sidebar-block__description">Специализированный принтер для печати ценников</p>
          <div class="info__detail">
            <img
              src="/img/integrations/icon-info-blue.svg"
              alt=""
            />
            <a target="_blank">Подробная справка</a>
            по интеграции
          </div>
        </div>

        <form class="form-block-bixolon">
          <p class="form-block-bixolon__label">Модель</p>
          <div style="width: 100%">
            <e-select
              v-model="modelPrinter"
              class="e-select-el"
              :options="optionModelPrinter"
              serchable
              :max_selected_count="1"
              placeholder="Выбeрите"
              select_name="entity_select"
            >
              <template #custom_name="{ item }">
                <div>
                  <p class="e-select-el__option-name">{{ item.name }}</p>
                </div>
              </template>
            </e-select>
          </div>

          <p class="form-block-bixolon__label">Название</p>
          <input
            v-model="namePrinter"
            class="form-block-bixolon__input"
            type="text"
            placeholder="Введите"
          />

          <p class="form-block-bixolon__label">Магазин</p>
          <div style="width: 100%">
            <e-select
              v-model="nameShop"
              class="e-select-el"
              :options="getBranchList"
              serchable
              :max_selected_count="1"
              placeholder="Выбeрите"
              select_name="entity_select"
            >
              <template #custom_name="{ item }">
                <div>
                  <p class="e-select-el__option-name">{{ item.name }}</p>
                  <div class="e-select-el__option-description-block">
                    <p class="e-select-el__option-description">{{ item.address.city }}</p>
                    <p class="e-select-el__option-description">{{ item.address.street }}</p>
                    <p class="e-select-el__option-description">{{ item.address.apartment }}</p>
                  </div>
                </div>
              </template>
            </e-select>
          </div>
        </form>
      </template>

      <div
        v-if="errorInputText"
        class="sidebar-block__error"
      >
        Все поля должны быть заполнены!
      </div>

      <template #footer="{ hide }">
        <div class="b-action-buttons">
          <b-button
            variant="primary"
            class="cursor"
            @click="setPrinter(hide)"
          >
            Продолжить
          </b-button>
          <b-button
            variant="outline-primary"
            class="cursor ml-3"
            @click="cancellationPrinterSidebar(hide)"
          >
            Отмена
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </section>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Axios from 'axios'
  export default {
    name: 'PrinterSidebar',

    data: () => ({
      modelPrinter: [],
      nameShop: [],
      namePrinter: '',
      optionModelPrinter: [{ value: 'Bixolon SRP-350 plus', name: 'Bixolon SRP-350 plus' }],
      errorInputText: false
    }),

    watch: {
      modelPrinter: {
        handler(newVal) {
          this.namePrinter = newVal[0]?.name
        },
        deep: true
      }
    },

    computed: {
      ...mapGetters({
        getBranchList: 'settings/getBranchList'
      })
    },

    methods: {
      downloadAgent() {
        Axios({
          url: 'https://s3.entersight.ru/agent/agent.msi',
          method: 'GET',
          responseType: 'blob'
        }).then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'agent.msi')
          document.body.appendChild(fileLink)

          fileLink.click()
        })
      },

      setPrinter(hide) {
        if (this.nameShop.length === 0 || this.namePrinter.length === 0) {
          this.errorInputText = true
        } else {
          this.errorInputText = false
          this.$apollo
            .mutate({
              mutation: require('../../gql//CreatePrinterDevice.gql'),
              variables: {
                input: {
                  name: this.modelPrinter[0]?.value,
                  type: 'bixolon',
                  tags: ['printer'],
                  branch: this.nameShop[0]?.id
                }
              }
            })
            .then(() => {
              this.$emit('refetch')
              this.$noty.show(`Принтер создан`)
              this.nameShop = []
              this.namePrinter = []
              hide()
            })
            .catch((e) => {
              console.error('error: ', e)
              this.$noty.show(`Что-то пошло не так. Попробуйте еще раз`)
            })
        }
      },

      cancellationPrinterSidebar(hide) {
        this.modelPrinter = []
        this.nameShop = []
        this.namePrinter = ''
        this.errorInputText = false
        hide()
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    #printer-sidebar {
      width: 680px !important;
      background: #fff;
    }

    .b-sidebar-header {
      position: relative;
      background: #fff;
      height: 85px;
    }

    .b-sidebar-footer {
      position: relative;
      background: #fff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      height: 65px;
      display: flex;
    }

    .b-sidebar-body {
      background-color: #fff;
      padding: 0;

      .sidebar-block {
        &__title {
          color: #000;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }

      .form-block-bixolon {
        .e-select-el .ms__chose {
          height: 42px;
        }

        &__title {
          color: #000;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }

        &__input-box-loading-catalog {
          width: 100%;
          display: flex;
          align-items: center;
          border-radius: 2px;
          border: 1px solid var(--text-placeholder-gray-300, #bcbcbc);
          margin-bottom: 22px;
        }

        &__input-loading-catalog {
          width: 100%;
          border: none;
          background: var(--gray-white, #fff);
          padding-left: 10px;
          padding-right: 12px;
          padding-top: 6px;
          padding-bottom: 6px;
          height: 42px !important;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
        }

        &__link-on-loading-catalog {
          margin-right: 12px;
        }
      }

      .sidebar-block-step-intigration {
        margin-top: 22px;
        margin-left: 22px;

        &__title {
          color: var(--text-primary-gray-700, #313131);
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
        }
      }

      .sidebar-block-finish-integration {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 74px;

        &__text {
          margin-top: 32px;
          margin-bottom: 32px;
          color: var(--text-primary-gray-700, #313131);
          text-align: center;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        &__button {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          padding: 22px;
          margin-top: 32px;
        }
      }
    }
  }

  #printer-inner {
    #printer-sidebar {
      width: 680px !important;
      background: #fff;
      .b-sidebar-header {
        img.close-sidebar {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 22px;
        }
      }

      .b-sidebar-footer {
        .b-action-buttons {
          padding: 0 22px 0;
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }

      .b-sidebar-body {
        .sidebar-block {
          border-bottom: 1px solid #e1e1e1;
          padding: 22px;
          margin-bottom: 22px;

          &__description {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }

          &__error {
            text-align: center;
            margin-top: 30px;
            color: red;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }

        .info {
          background-color: #fff;
          padding-bottom: 22px;
          border-bottom: 1px solid #e1e1e1;
          font-size: 16px;
          line-height: 24px;
          color: #000000;

          &__detail {
            background: #e6f0ff;
            border-radius: 4px;
            margin-top: 22px;
            border-left: 3px solid #b9d6ff;
            padding: 15px;

            img {
              margin-right: 15px;
            }

            a {
              color: #313131;
              font-weight: 600;
              border-bottom: 1px solid #313131;
            }
          }
        }

        .form-block-bixolon {
          margin-left: 22px;
          margin-right: 22px;

          .e-select-el {
            &__option-name {
              margin: 6px 0px 6px 0px;
              text-align: left;
              color: var(--text-primary-gray-700, #313131);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px;
            }
            &__option-description-block {
              display: flex;
              margin-bottom: 6px;
            }
            &__option-description {
              margin: 0px 5px 0px 0px;
              text-align: left;
              color: var(--text-secondary-gray-400, #888);
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px;
            }
          }

          &__label {
            margin-bottom: 6px;
            color: var(--text-primary-gray-700, #313131);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            margin-top: 22px;
          }

          &__input {
            width: 100%;
            border-radius: 2px;
            border: 1px solid var(--text-placeholder-gray-300, #bcbcbc);
            background: var(--gray-white, #fff);
            padding-left: 10px;
            padding-right: 12px;
            padding-top: 6px;
            padding-bottom: 6px;
            height: 42px !important;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
          }
          &__input::-webkit-input-placeholder {
            color: rgb(188, 188, 188);
          }
          &__input:-moz-placeholder {
            color: red;
          }
          &__input::-moz-placeholder {
            color: red;
          }
          &__input:-ms-input-placeholder {
            color: red;
          }
        }
      }
      .sidebar-block__error {
        color: red;
      }
    }
  }
</style>
