<template>
  <b-modal
    id="frontol-boot-options"
    centered
    hide-footer
    size="lg"
    :no-close-on-backdrop="true"
  >
    <template #modal-header>
      <div class="header-container">
        <div class="title-block d-flex justify-content-between w-100">
          <div class="header-block-title">
            <h5 class="title">Загрузка кассы</h5>

            <div class="header-descriptoin">
              <div class="header-descriptoin__delimiter"></div>
              <div>Касса №{{ cash_device.number }}</div>
              <div class="header-descriptoin__delimiter"></div>
              <div
                :class="{
                  'active-device': cash_device.active,
                  'no-active-device': !cash_device.active
                }"
              >
                {{ cash_device.active ? 'Активна' : 'Не активеа' }}
              </div>
            </div>
          </div>

          <b-button class="close">
            <img
              src="/img/icons/modal_close.svg"
              alt=""
              @click="hideModal"
            />
          </b-button>
        </div>

        <div
          v-if="showInfo"
          class="info-container"
        >
          <div class="d-flex align-items-center">
            <img
              src="/img/icons/info-blue.svg"
              alt="info"
            />
            <p>Требуется загрузить параметры системы для работы с новой кассой</p>
          </div>
        </div>
      </div>
    </template>

    <div class="modal-body">
      <div class="modal-content">
        <form class="modal-content__form">
          <div class="modal-content__form-checkbox-box">
            <b-form-checkbox v-model="isDownloadStaff" />
            <label class="modal-content__form-checkbox-box-label">Персонал</label>
          </div>

          <template v-if="isDownloadStaff">
            <div
              v-for="(data, index) in listOfEmployeesToLoading"
              :key="index"
              class="modal-content__form-block"
            >
              <div class="modal-content__form-box">
                <label class="modal-content__label">Сотрудник</label>
                <div class="modal-content__form-box-block-select">
                  <e-select
                    v-model="data.employee"
                    class="e-select-el"
                    :options="getUsers"
                    serchable
                    :max_selected_count="1"
                    placeholder="Выберите сотрудника"
                    select_name="users_select"
                  >
                    <template #custom_name="{ item }">
                      <div>
                        <p class="e-select-el__option-name">{{ `${item.last_name ?? ''} ${item.first_name}` }}</p>
                        <div class="e-select-el__option-description-block">
                          <p
                            v-if="item.roles !== null"
                            class="e-select-el__option-description"
                          >
                            {{ item.roles === null ? '' : item.roles[0].name }}
                          </p>
                          <div
                            v-if="item.roles !== null"
                            class="e-select-el__option-delimiter"
                          ></div>
                          <p class="e-select-el__option-description">{{ cash_device.branch.name }}</p>
                          <p class="e-select-el__option-description">в городе</p>
                          <p class="e-select-el__option-description">{{ cash_device.branch.address.city }},</p>
                          <p class="e-select-el__option-description">{{ cash_device.branch.address.street }}</p>
                          <p class="e-select-el__option-description">{{ cash_device.branch.address.apartment }}</p>
                        </div>
                      </div>
                    </template>
                  </e-select>
                </div>
              </div>

              <div class="modal-content__form-box">
                <div class="modal-content__label-password-block">
                  <label class="modal-content__label">Пароль от кассы</label>
                  <p
                    class="cursor modal-content__generate-pass-button"
                    @click="getGeneratePass(index)"
                  >
                    Сгенерировать
                  </p>
                </div>
                <div class="modal-content__form-box-block-select">
                  <input
                    v-model="data.checkoutPassword"
                    class="modal-content__input"
                    type="number"
                  />
                </div>
              </div>
              <img
                v-if="index > 0"
                class="cursor"
                style="margin-top: 20px"
                src="/img/integrations/frontol/bin.svg"
                alt=""
                @click="deleteEmployeeToLoadTheCashRegister(data.id)"
              />
            </div>
            <div class="modal-content__form-block-button">
              <button
                class="modal-content__form-button"
                @click.prevent="addEmployeeToLoadTheCashRegister"
              >
                <img
                  class="cursor"
                  style="margin-top: 23px"
                  src="/img/integrations/frontol/Plus.svg"
                  alt=""
                />
                <p class="modal-content__form-button-text">Добавить</p>
              </button>
            </div>
          </template>
          <template v-else>
            <p class="modal-content__info-download-staff">
              Персонал на кассу не будет загружен, вам потребуется завести его самостоятельно через кассу
            </p>
          </template>

          <div class="modal-content__form-checkbox-box">
            <b-form-checkbox
              v-model="isDiscountsAndRestrictions"
              :disabled="true"
            />
            <label class="modal-content__form-checkbox-box-label-in-developing">Скидки и ограничения</label>
            <div class="in-developing">в разработке</div>
          </div>

          <div class="modal-content__form-checkbox-box">
            <b-form-checkbox
              v-model="isDiscountCards"
              :disabled="true"
            />
            <label class="modal-content__form-checkbox-box-label-in-developing">Дисконтные карты</label>
            <div class="in-developing">в разработке</div>
          </div>
        </form>
      </div>

      <template>
        <div class="modal-body__footer">
          <div class="b-action-buttons">
            <b-button
              variant="primary"
              class="cursor"
              @click="saveTheLoadingCashDevice"
            >
              Загрузить
            </b-button>
            <b-button
              variant="outline-primary"
              class="cursor ml-3"
              @click="hideModal"
            >
              Отмена
            </b-button>
          </div>
        </div>
      </template>
    </div>
  </b-modal>
</template>

<script>
  export default {
    name: 'FrontolBootOptionsModal',

    props: {
      cash_device: {
        type: Object,
        default: () => {}
      }
    },

    data() {
      return {
        showInfo: true,
        isDiscountCards: false,
        isDownloadStaff: true,
        isDiscountsAndRestrictions: false,
        listOfEmployeesToLoading: [{ id: 1, employee: [], checkoutPassword: '' }]
      }
    },

    computed: {
      getUsers() {
        if (this.cash_device.id) {
          const userList = []
          const branchUsers = this.cash_device.branch.users ?? []
          branchUsers.forEach((el) => {
            const user = {
              id: el.id,
              first_name: el.first_name,
              last_name: el.last_name,
              name: `${el.last_name ?? ''} ${el.first_name ?? ''}`,
              roles: el.roles
            }
            userList.push(user)
          })
          return userList
        } else return []
      }
    },

    methods: {
      hideModal() {
        this.$bvModal.hide('frontol-boot-options')
        this.clearForm()
      },
      clearForm() {
        this.listOfEmployeesToLoading.forEach((el) => {
          el.employee.pop()
          el.checkoutPassword = ''
        })
        this.isDiscountCards = false
        this.isDownloadStaff = false
        this.isDiscountsAndRestrictions = false
      },

      addEmployeeToLoadTheCashRegister() {
        const newEmployee = { id: Date.now(), employee: [], checkoutPassword: '' }
        this.listOfEmployeesToLoading.push(newEmployee)
      },

      deleteEmployeeToLoadTheCashRegister(id) {
        this.listOfEmployeesToLoading = this.listOfEmployeesToLoading.filter((el) => el.id !== id)
      },

      getGeneratePass(index, countNumber = 3) {
        let result = ''

        for (let i = 0; i < countNumber; i++) {
          let number = Math.floor(Math.random() * (10 - 0) + 0)
          result += number
        }
        this.listOfEmployeesToLoading[index].checkoutPassword = result
      },

      async saveTheLoadingCashDevice() {
        try {
          if (this.isDownloadStaff) {
            const isValidation = this.listOfEmployeesToLoading.find((el) => {
              if (el.employee.length === 0 || el.checkoutPassword === '') {
                return el
              }
            })
            if (isValidation) {
              this.$noty.show(
                `Поля "Сотрудник" и "Пароль от кассы" должны быть заполнены если вы выбрали "Загрузить персонал"`
              )
              return
            } else {
              const users = []
              this.listOfEmployeesToLoading.forEach((el) => {
                const user = {
                  id: el.employee[0].id,
                  frontol_pass: el.checkoutPassword
                }
                users.push(user)
              })

              await this.$apollo.mutate({
                mutation: require('../gql/UpdateUsers.gql'),
                variables: {
                  input: {
                    users: users
                  }
                }
              })
            }
          }

          await this.$apollo.mutate({
            mutation: require('../gql/updateCash.graphql'),
            variables: {
              input: {
                id: this.cash_device?.id,
                is_disc_card: this.isDiscountCards,
                is_discounts: this.isDiscountsAndRestrictions,
                upload_users: this.isDownloadStaff,
                branch: this.cash_device?.branch.id
              }
            }
          })

          this.$emit('refetch')
          this.$noty.show(`Загрузка кассы сохранена!`)
          this.hideModal()
        } catch (err) {
          console.error('error: ', err)
          this.$noty.show(`Что-то пошло не так. Попробуйте еще раз`)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  #frontol-boot-options {
    .header-container {
      width: 100%;
      .header-block-title {
        display: flex;
        align-items: center;
      }
      .header-descriptoin {
        margin-top: 2px;
        display: flex;
        align-items: center;
        color: var(--text-primary-gray-700, #313131);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        &__delimiter {
          border-radius: 14px;
          background: var(--text-placeholder-gray-300, #bcbcbc);
          width: 1px;
          height: 12px;
          margin-left: 11px;
          margin-right: 11px;
        }

        .active-device {
          color: var(--text-successful-green-400, #00cb91);
        }
        .no-active-device {
          color: red;
        }
      }
      .title-block {
        margin-bottom: 16px;
        .title {
          margin: 0;
          font-family: 'Gilroy-Medium', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          color: #313131;
        }
      }
      .info-container {
        margin-bottom: 22px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px;
        width: 100%;
        background: #eef9ff;
        border: 1px solid #99daff;
        border-radius: 2px;
        .close-help {
          cursor: pointer;
        }
        p {
          margin: 0 0 0 13px;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #313131;
        }

        &__link {
          color: var(--text-system-blue-400, #00a3ff);
          text-decoration-line: underline;
        }
      }
    }

    .modal-body {
      &__footer {
        margin-top: 22px;
        margin-bottom: 22px;
      }
    }
    .modal-content {
      padding: 0;
      &__form-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
      }

      &__form-box {
        width: 100%;
        margin-right: 16px;
      }

      &__form-checkbox-box {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      }
      &__form-checkbox-box:nth-of-type(2) {
        display: flex;
        align-items: center;
        margin-top: 22px;
      }
      &__form-checkbox-box:nth-of-type(3) {
        display: flex;
        align-items: center;
        margin-top: 22px;
      }
      &__form-checkbox-box-label {
        margin: 0;
        color: var(--text-primary-gray-700, #313131);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      &__form-checkbox-box-label-in-developing {
        margin: 0;
        color: var(--secondary-b-300, #a4a9b8);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      &__modal-content__info-download-staff {
        color: var(--text-secondary-gray-400, #888);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      &__label-password-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &__label {
        color: var(--text-primary-gray-700, #313131);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: -2px;
      }

      &__generate-pass-button {
        color: var(--text-system-blue-400, #00a3ff);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        text-decoration-line: underline;
        margin-bottom: 2px;
      }

      &__form-block-button {
        margin-top: 10px;
        margin-bottom: 22px;
      }

      &__form-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: none;
        width: 83px;
        background-color: white;

        img {
          margin: 0 !important;
        }
      }

      &__form-button-text {
        margin: 0;
        color: var(--text-system-blue-400, #00a3ff);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      &__form-box-block-select {
        width: 100%;
        display: flex;
        align-items: center;
      }

      &__input {
        width: 100%;
        border-radius: 2px;
        border: 1px solid var(--text-placeholder-gray-300, #bcbcbc);
        background: var(--gray-white, #fff);
        padding-left: 10px;
        padding-right: 12px;
        padding-top: 6px;
        padding-bottom: 6px;
        height: 34px !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        margin-top: 3px;
      }
      &__input::-webkit-input-placeholder {
        color: rgb(188, 188, 188);
      }
      &__input:-moz-placeholder {
        color: red;
      }
      &__input::-moz-placeholder {
        color: red;
      }
      &__input:-ms-input-placeholder {
        color: red;
      }

      .e-select-el {
        &__option-name {
          margin: 6px 0px 6px 0px;
          text-align: left;
          color: var(--text-primary-gray-700, #313131);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
        }
        &__option-description-block {
          display: flex;
          margin-bottom: 6px;
        }
        &__option-description {
          margin: 0px 5px 0px 0px;
          text-align: left;
          color: var(--text-secondary-gray-400, #888);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
        }
        &__option-delimiter {
          border-radius: 14px;
          background: var(--text-placeholder-gray-300, #bcbcbc);
          width: 1px;
          height: 12px;
          margin-right: 5px;
        }
      }
    }
  }
  .in-developing {
    color: var(--secondary-b-300, #a4a9b8);
    margin-left: 5px;
    display: flex;
    padding: 2px 6px;
    justify-content: center;
    align-items: center;

    border-radius: 100px;
    background: var(--secondary-b-50, #f2f3f6);
  }
</style>
