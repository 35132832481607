var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-sidebar', {
    attrs: {
      "id": "frontol-device-sidebar",
      "title": "Frontol 6",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    on: {
      "shown": _vm.shownSidebar,
      "hidden": _vm.closeSidebar
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref) {
        var hide = _ref.hide;
        return [_vm._v(" Frontol 6 "), _c('div', {
          staticClass: "header-left-block"
        }, [_c('img', {
          staticClass: "close-sidebar cursor",
          attrs: {
            "src": "/img/integrations/icon-sidebar-close.svg",
            "alt": ""
          },
          on: {
            "click": hide
          }
        })])];
      }
    }, {
      key: "footer",
      fn: function (_ref2) {
        var hide = _ref2.hide;
        return [_c('div', {
          staticClass: "b-action-buttons"
        }, [_c('b-button', {
          staticClass: "cursor",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.setFrontolDevice(hide);
            }
          }
        }, [_vm._v(" Подключить ")]), _c('b-button', {
          staticClass: "cursor ml-3",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function ($event) {
              return _vm.closeSidebar(hide);
            }
          }
        }, [_vm._v(" Отмена ")])], 1)];
      }
    }])
  }, [_c('print-group-setting', {
    on: {
      "close_switch": function ($event) {
        _vm.isSwitchActive = false;
      }
    }
  }), _c('frontol-boot-options-modal', {
    attrs: {
      "cash_device": _vm.dataOfTheCreatedCheckout
    },
    on: {
      "refetch": _vm.refetch
    }
  }), _c('div', {
    staticClass: "sidebar-block info"
  }, [_c('p', [_vm._v(" Профессиональное программное обеспечение для автоматизации рабочих мест кассира предприятий розничной торговли и общественного питания любых форматов и размеров ")]), _c('div', {
    staticClass: "info__detail"
  }, [_c('img', {
    attrs: {
      "src": "/img/integrations/icon-info-blue.svg",
      "alt": ""
    }
  }), _c('router-link', {
    attrs: {
      "to": "#"
    }
  }, [_vm._v("Подробная справка")]), _vm._v(" по интеграции ")], 1)]), [_c('div', {
    staticClass: "frontol-content"
  }, [_c('h3', {
    staticClass: "frontol-content__title"
  }, [_vm._v("Укажите параметры обмена с кассовым ПО")]), _c('form', {
    staticClass: "form-block-frontol"
  }, [_c('label', {
    staticClass: "form-block-frontol__label"
  }, [_vm._v("Номер кассы")]), _c('e-input', {
    staticClass: "form-block-frontol__input",
    attrs: {
      "placeholder": "Введите номер",
      "type": "number"
    },
    model: {
      value: _vm.numberCash,
      callback: function ($$v) {
        _vm.numberCash = $$v;
      },
      expression: "numberCash"
    }
  }), _c('label', {
    staticClass: "form-block-frontol__label"
  }, [_vm._v("Наименование")]), _c('e-input', {
    staticClass: "form-block-frontol__input",
    attrs: {
      "placeholder": "Введите имя"
    },
    model: {
      value: _vm.nameCash,
      callback: function ($$v) {
        _vm.nameCash = $$v;
      },
      expression: "nameCash"
    }
  }), _c('label', {
    staticClass: "form-block-frontol__label"
  }, [_vm._v("Магазин")]), _c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('e-select', {
    staticClass: "e-select-el",
    attrs: {
      "options": _vm.getBranchList,
      "serchable": "",
      "max_selected_count": 1,
      "placeholder": "Выберите магазин",
      "select_name": "entity_select"
    },
    scopedSlots: _vm._u([{
      key: "custom_name",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('div', [_c('p', {
          staticClass: "e-select-el__option-name"
        }, [_vm._v(_vm._s(item.name))]), _c('div', {
          staticClass: "e-select-el__option-description-block"
        }, [_c('p', {
          staticClass: "e-select-el__option-description"
        }, [_vm._v(_vm._s(item.address.city))]), _c('p', {
          staticClass: "e-select-el__option-description"
        }, [_vm._v(_vm._s(item.address.street))]), _c('p', {
          staticClass: "e-select-el__option-description"
        }, [_vm._v(_vm._s(item.address.apartment))])])])];
      }
    }]),
    model: {
      value: _vm.shopName,
      callback: function ($$v) {
        _vm.shopName = $$v;
      },
      expression: "shopName"
    }
  })], 1), _c('label', {
    staticClass: "form-block-frontol__label"
  }, [_vm._v("Место хранения")]), _c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('e-select', {
    staticClass: "e-select-el",
    attrs: {
      "options": _vm.storageList,
      "serchable": "",
      "max_selected_count": 1,
      "placeholder": "Выберите место хранения",
      "select_name": "entity_select"
    },
    scopedSlots: _vm._u([{
      key: "custom_name",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('div', [_c('p', {
          staticClass: "e-select-el__option-name"
        }, [_vm._v(_vm._s(item.name))])])];
      }
    }]),
    model: {
      value: _vm.storage,
      callback: function ($$v) {
        _vm.storage = $$v;
      },
      expression: "storage"
    }
  })], 1), _c('label', {
    staticClass: "form-block-frontol__label"
  }, [_vm._v("Организция")]), _c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('e-select', {
    staticClass: "e-select-el",
    attrs: {
      "options": _vm.entities,
      "serchable": "",
      "max_selected_count": 1,
      "placeholder": "Выберите организацию",
      "select_name": "entity_select"
    },
    scopedSlots: _vm._u([{
      key: "custom_name",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('div', [_c('p', {
          staticClass: "e-select-el__option-name"
        }, [_vm._v(_vm._s(item.name))])])];
      }
    }]),
    model: {
      value: _vm.entity,
      callback: function ($$v) {
        _vm.entity = $$v;
      },
      expression: "entity"
    }
  })], 1), _c('label', {
    staticClass: "form-block-frontol__label"
  }, [_vm._v(" Каталог загрузки "), _c('div', {
    staticClass: "form-block-frontol__lable-img-block"
  }, [_c('img', {
    staticClass: "cursor",
    staticStyle: {
      "margin-left": "3px"
    },
    attrs: {
      "src": "/img/integrations/frontol/Info.svg",
      "alt": ""
    },
    on: {
      "mouseenter": _vm.showLabellModalInPath,
      "mouseleave": _vm.closeLabellModalInPath
    }
  }), _c('label-input-modal', {
    attrs: {
      "is_show": _vm.isShowModalInPath
    }
  }, [_c('p', {
    staticClass: "text-label-input-modal"
  }, [_vm._v("Путь до места загрузки данных на кассу")])])], 1)]), _c('e-input', {
    staticClass: "form-block-frontol__input",
    attrs: {
      "placeholder": "C:\\in_path\\",
      "error": _vm.errorCatalogInPath
    },
    model: {
      value: _vm.catalogInPath,
      callback: function ($$v) {
        _vm.catalogInPath = $$v;
      },
      expression: "catalogInPath"
    }
  }), _c('label', {
    staticClass: "form-block-frontol__label"
  }, [_vm._v(" Каталог выгрузки "), _c('div', {
    staticClass: "form-block-frontol__lable-img-block"
  }, [_c('img', {
    staticClass: "cursor",
    staticStyle: {
      "margin-left": "3px"
    },
    attrs: {
      "src": "/img/integrations/frontol/Info.svg",
      "alt": ""
    },
    on: {
      "mouseenter": _vm.showLabellModalOutPath,
      "mouseleave": _vm.closeLabellModalOutPath
    }
  }), _c('label-input-modal', {
    attrs: {
      "is_show": _vm.isShowModalOutPath
    }
  }, [_c('p', {
    staticClass: "text-label-input-modal"
  }, [_vm._v("Путь до места выгрузки данных с кассы")])])], 1)]), _c('e-input', {
    staticClass: "form-block-frontol__input",
    attrs: {
      "placeholder": "C:\\out_path\\",
      "error": _vm.errorCatalogOutPath
    },
    model: {
      value: _vm.catalogOutPath,
      callback: function ($$v) {
        _vm.catalogOutPath = $$v;
      },
      expression: "catalogOutPath"
    }
  }), _c('div', {
    staticClass: "form-block-frontol__block-chekbox"
  }, [_c('b-form-checkbox', {
    attrs: {
      "checked": _vm.isSwitchActive,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": _vm.changeSwitchActive
    }
  }), _c('label', {
    staticStyle: {
      "margin-bottom": "0"
    }
  }, [_vm._v(" Использовать Группы печати "), _c('img', {
    staticClass: "cursor",
    staticStyle: {
      "margin-left": "6px"
    },
    attrs: {
      "src": "/img/integrations/frontol/settings.svg",
      "alt": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$bvModal.show('print-group-setting');
      }
    }
  }), _c('img', {
    staticClass: "cursor",
    staticStyle: {
      "margin-left": "6px"
    },
    attrs: {
      "src": "/img/integrations/frontol/Info.svg",
      "alt": ""
    },
    on: {
      "mouseenter": _vm.showLabellModalGroupPrint,
      "mouseleave": _vm.closeLabellModalGroupPrint
    }
  }), _c('label-input-modal', {
    attrs: {
      "is_show": _vm.isShowModalGroupPrint
    }
  }, [_c('p', {
    staticClass: "text-label-input-modal"
  }, [_vm._v(" Механизм применяется, когда требуется разделить продажу товара по разным ККМ ")])])], 1)], 1)], 1)])]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }