var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": "download-cash-docs",
      "centered": "",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function () {
        return [_c('h5', [_vm._v("Выгрузка продаж с кассы")])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "info-message mb-3"
  }, [_vm._v(" Система перезапишет кассовые документы за выбранный вами период при загрузке данных ")]), _vm._v(" Период выгрузки "), _c('work-period', {
    staticClass: "mb-3",
    attrs: {
      "type_doc": "cash"
    },
    on: {
      "date_range": _vm.setDateRange
    }
  }), _c('div', {
    staticClass: "d-flex mb-4"
  }, [_c('e-button', {
    staticClass: "mr-3",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.download
    }
  }, [_vm._v(" Выгрузить ")]), _c('e-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v(" Отмена ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }